import styled from "styled-components";

export const Pre = styled.pre`
  text-align: left;
  margin: 0;
  padding: 0.5em;
  
  font-size: 16px;

  overflow: scroll;
  max-height: 50vh;
  
  & .token-line {
    line-height: 1.3em;
    height: 1.3em;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
  }
`;