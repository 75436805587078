import React from 'react'
import { H1, Text, Container, FlexRow, FlexColumn } from '@utdanningsdirektoratet/lisa'
import '../../index.css'
import '../../App.css'

function NumberIcon({ label, number, className }) {
    const padding = {
        right: 12,
        top: 4,
        bottom: 4, 
        left: 12,
    }

    return (
        <FlexRow className={className} marginBetween="8">
            <FlexColumn><Container className="borderCircle mb-12" padding={padding} backgroundColor="hvit"><Text textStyle="bodyBold">{number}</Text></Container> </FlexColumn>
            <FlexColumn><H1 className='mb-12'>{label}</H1></FlexColumn>
        </FlexRow>
    );
}

export default NumberIcon
