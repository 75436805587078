import React from 'react';
import { Provider } from 'react-redux'
import Store from '../src/app/store'
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import './app/views/ApiKonsoll/FilterOptions/styles/main.scss'
import { LisaProvider } from '@utdanningsdirektoratet/lisa'


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <LisaProvider includeSpacing>
    <Provider store={Store}>
      <App />
      </Provider>
    </LisaProvider>
  </React.StrictMode>
);