import React, { useEffect, useState } from 'react'
import { Button, FlexColumn, FlexRow, Grid, GridItem, Label, Loading, Select, Text } from '@utdanningsdirektoratet/lisa'
import SyntaxHighlight from '../syntaxHighlighter/SyntaxHighlight'
import LisaTooltip from '../listaTooltip/LisaTooltip';
import { Alert, AlertTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useUrl } from '../../app/hooks/useUrl';
import { setSelectedFilterId } from '../../app/views/ApiKonsoll/FilterOptions/ducks/filter/pageData';
import axios from 'axios';

function UtviklerdokumentasjonFilterStatus() {
    const dispatch = useDispatch()

    const filterSpec = useSelector(state => state.filter.pageData.filterSpecResponse.filterSpec)
    const selectedFilterId = useSelector(state => state.filter.pageData.selectedFilterId)
    const [loadingFilterStatus, setLoadingFilterStatus] = useState(false);
    const urlFilterStatus = useUrl("/filterStatus")
    const [filterStatusResponse, setFilterStatusResponse] = useState(null);
    const [filterStatusResponseError, setFilterStatusResponseError] = useState(null);
    const [filterStatusResponseStatus, setFilterStatusResponseStatus] = useState(null)    
    const [selectedFilter, setSelectedFilter] = useState(0)
    const availableFilters = []; 

    let value = 0;
    {
        for (const key in filterSpec) {
            if (filterSpec.hasOwnProperty(key)) {
                availableFilters.push({ value: value, label: filterSpec[key].navn, id: filterSpec[key].id });
                value++;
            }
        }
    }
    
    useEffect(() => {
        if (selectedFilterId == null) {
          const filterId = availableFilters.find(item => item.value === 0)?.id;
          dispatch(setSelectedFilterId(filterId));
        }
      }, []);

    const getFilterStatusResponse = async () => { 
        setLoadingFilterStatus(true)

        try {
            const response = await axios.get(urlFilterStatus)
            setFilterStatusResponse(response.data)
            setFilterStatusResponseStatus(response.status)
            setFilterStatusResponseError(null)
    
        } catch (error) {
            setFilterStatusResponseError(error)
            setFilterStatusResponseStatus(error.request.status)
        }
        setLoadingFilterStatus(false)
    }

    const changeSelectedFilter = (selectedFilterValue) => {
        setSelectedFilter(selectedFilterValue)
        const filterId = availableFilters.find(item => item.value === selectedFilterValue)?.id 
        dispatch(setSelectedFilterId(filterId))
    }

    return (
        <>
            <FlexRow halign='space-between' valign='end' className='mt-12'>
                <FlexColumn>
                    <LisaTooltip label={<Label>Velg filter</Label>} content="Velg filter som URL-spørringen tar utganspunkt i med nedtrekksmenyen."></LisaTooltip>
                    {availableFilters.length &&
                        <Select
                        label="Velg filter"
                        hideLabel={true}
                        dropdownStyle="outlined"
                        width="auto"
                        value={selectedFilter}
                        onChange={(value) => changeSelectedFilter(value)}
                        items={availableFilters}
                        />
                    }
                </FlexColumn>
                <FlexColumn>
                    <Button onClick={() => getFilterStatusResponse()}>Test URL-spørring</Button>
                </FlexColumn>
            </FlexRow>   

            <Label className='mt-8'>Curl</Label>
            <div className="mb-8"><SyntaxHighlight label="Curl" language="language-bash" code={`curl -X 'GET' \\ \n '${urlFilterStatus}' \\ \n -H 'accept: */*'`} /></div>
            
            <Label>URL-spørring</Label>
            <div className="mb-8"><SyntaxHighlight label="URL-spørring" language="language-javascript" code={urlFilterStatus} /></div>
            
            { loadingFilterStatus || filterStatusResponseStatus === null ? <Loading className="mt-16" pending={loadingFilterStatus} size="large" /> :
                <>
                    <LisaTooltip className='mt-8' label={<Label>Status</Label>} content="Betydning av statuskoder: 200 = Suksess, 400 = Ugyldig forespørsel (inkludert feil bruk av parametere), og 500 = Serverfeil." noMargin></LisaTooltip>
                    <Grid gap="none">
                        <GridItem columns={{xsmall: 12, small:1}}><Text className="mb-8">{filterStatusResponseStatus}</Text></GridItem>
                        { (filterStatusResponseError != null) && 
                            <GridItem className="ml-20" columns={{xsmall: 12, small:11}}>
                                <Alert className="mb-8" severity="error">
                                    <AlertTitle>API-kallet feilet - Statuskode {filterStatusResponseError.response.status} {filterStatusResponseError.response.statusText}</AlertTitle> 
                                </Alert>
                            </GridItem>
                        }
                    </Grid>
                    { (filterStatusResponseError == null) && 
                        <>
                            <Label>Resultat</Label>
                            <div className="mb-8">
                                <SyntaxHighlight label="Resultat" language="language-json" code={JSON.stringify(filterStatusResponse, null, " ")}/>
                            </div>
                        </>
                    }
                </>
            }
        </>
    );
}

export default UtviklerdokumentasjonFilterStatus