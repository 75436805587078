import { Map, fromJS } from 'immutable'

export const types = {
	REQUEST_TEXT: 'REQUEST_TEXT',
	RECEIVE_TEXT: 'RECEIVE_TEXT',
	SET_TEXT: 'SET_TEXT',
	SET_ACTIVE_TEXT_ID: 'SET_ACTIVE_TEXT_ID',
	REMOVE_ACTIVE_TEXT_ID: 'REMOVE_ACTIVE_TEXT_ID'
}

const initialState = Map({
	isFetching: false,
	activeTextKey: null
})

export default (state = initialState, action) => {
	switch (action.type) {
		case types.REQUEST_TEXT:
			return state.set('isFetching', true)
		case types.RECEIVE_TEXT:
			state = state.set('isFetching', false)
			return state.setIn(['data', action.textId], action.textValue)
		case types.SET_TEXT:
			return state.mergeDeep(fromJS({ data: action.textObject }))
		case types.SET_ACTIVE_TEXT_ID:
			return state.set('activeTextKey', action.activeTextKey)
		case types.REMOVE_ACTIVE_TEXT_ID:
			return state.set('activeTextKey', null)
		default:
			return state
	}
}

export const setActiveTextKey = activeTextKey => ({
	type: types.SET_ACTIVE_TEXT_ID,
	activeTextKey
})

export const removeActiveTextKey = () => ({
	type: types.REMOVE_ACTIVE_TEXT_ID
})

export const requestText = textId => ({
	type: types.REQUEST_TEXT,
	textId
})

export const receiveText = (textId, textValue) => ({
	type: types.RECEIVE_TEXT,
	textId,
	textValue
})

export const setText = textObject => ({
	type: types.SET_TEXT,
	textObject
})

export const getTextById = (textId, textKey) => dispatch =>
	dispatch(fetchText(textId)).then(() => dispatch(setActiveTextKey(textKey)))

export const fetchText = textId => (dispatch, getState) => {
	const { text, report } = getState()
	if (text.get('isFetching') || text.getIn(['data', textId])) return Promise.resolve()

	const url = report.getIn(['page', 'hentTekstEndepunkt'])
	const textUrl = url.replace('{tekstId}', textId)

	dispatch(requestText(textUrl))
}
