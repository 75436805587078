import React from 'react'
import { Container, FlexRow, H3 } from '@utdanningsdirektoratet/lisa'
import '../../index.css'
function GetIcon({ label }) {
    const padding = {
        right: 5,
        top: 2,
        bottom: 2, 
        left: 5,
    }

    return (
        <FlexRow>
            <Container className="mr-8" borderRadius="medium" padding={padding} backgroundColor="stålblå600"><H3 textStyle="bodyBold" className='m-0' id='httpText'>GET</H3></Container> 
            {label}
        </FlexRow>
    );
}

export default GetIcon
