import React, { Component } from 'react'
import { number, object, func, bool, string, array } from 'prop-types'
import Checkbox from '../../form/Checkbox'

export default class MultipleChoiceTreeFilterValue extends Component {
	static propTypes = {
		index: number,
		filterValues: object,
		selection: object,
		shouldExpand: bool,
		toggleFilterSelection: func.isRequired,
		setActiveTabState: func.isRequired,
		activeSearch: string,
		filter: object,
		showFilterCode: bool,
		filterStatus: object,
		matchingFilters: array,
		selectAllHandler: func,
		selectAllSubLevelHandler: func,
		hasActiveTabState: func,
		overrideExpandLevel: number
	}

	constructor(props) {
		super(props)
		const { index, filterValues, selection, shouldExpand, overrideExpandLevel } = props
		this.state = {
			expanded: selection && selection.has(filterValues.getIn([index, 'id'])) && shouldExpand,
		}

		if (overrideExpandLevel >= filterValues.getIn([index, 'nivaa'])) this.state = { expanded: true }
	}

	toggleTree = (event, id) => {
		this.props.setActiveTabState(id)
		this.setState(
			Object.assign({}, this.state, {
				expanded: !this.state.expanded,
			})
		)
	}

	searchIsActive = () => {
		const { activeSearch } = this.props
		return activeSearch && activeSearch.length > 0
	}

	// Render nodes on this level
	renderInput() {
		const {
			index,
			filter,
			filterValues,
			showFilterCode,
			toggleFilterSelection,
			setActiveTabState,
			filterStatus,
			selection,
		} = this.props
		
		const filterValue = filterValues.get(index)

		const selected = selection && selection.has(filterValue.get('id'))

		let countSelectedValues = 0

		if (selection && filterValue.get('barn')) {
			filterValue.get('barn').forEach((val) => {
				if (selection.has(filterValues.getIn([val, 'id']))) countSelectedValues++
			})
		}
		const active = filterStatus && filterStatus.includes(filterValue.get('id'))

		const checkedButton = selected || false
		const labelStyle = {
			color: active ? '' : '#ccc',
		}

		const showCountSelected = () =>
			!this.searchIsActive() && !this.state.expanded && countSelectedValues > 0

		const clickHandler = (event) => {
			toggleFilterSelection(
				filter.get('id'),
				event.currentTarget.value,
				filterValue.get('indeks'),
				filter.get('valgModus')
			)
			setActiveTabState(null)
		}

		return (
			<div className="nssReactApp">
				<Checkbox
					style={labelStyle}
					checked={checkedButton}
					value={filterValue.get('id')}
					onChange={clickHandler}
					disabled={!active}
					notClickable={filterValue.get('ikkeVelgbart', false)}
				>
					{showFilterCode
						? `${filterValue.get('kode')} - ${filterValue.get('navn')}`
						: filterValue.get('navn')}
					{showCountSelected()
						? ` (${countSelectedValues} av ${filterValue.get('barn').size} valgt)`
						: ''}
				</Checkbox>
			</div>
		)
	}

	// Main-rendering
	render() {
		const {
			index,
			filter,
			filterValues,
			filterStatus,
			matchingFilters,
			activeSearch,
			selection,
			selectAllHandler,
			selectAllSubLevelHandler,
			hasActiveTabState,
			setActiveTabState,
			toggleFilterSelection,
			overrideExpandLevel,
		} = this.props

		if (activeSearch && !matchingFilters.includes(index)) return false
		const filterValue = filterValues.get(index)

		if (filterValue.get('barn')) {
			const childNodes = filterValue.get('barn')
			const iconClass =
				this.state.expanded || activeSearch ? 'fa fa-minus-square' : 'fa fa-plus-square'
			const buttonText =
				this.state.expanded || activeSearch
					? `Lukk ${filterValue.get('navn')}`
					: `Ekspander ${filterValue.get('navn')}`
			const ExpandButton = () => (
				<button
					className="nssReactApp tree-button"
					key={filterValue.get('id')}
					tabIndex="0"
					autoFocus={hasActiveTabState(filterValue.get('id'))}
					aria-label={buttonText}
					onClick={(e) => this.toggleTree(e, filterValue.get('id'))}
					onKeyPress={(e) => this.toggleTree(e, filterValue.get('id'))}
				>
					<i className={iconClass} />
				</button>
			)
			return (
				<li>
					{!this.searchIsActive() && <ExpandButton />}
					{this.renderInput()}
					{(this.state.expanded || activeSearch) && (
						<ul>
							{childNodes.map((value, idx) => (
								<MultipleChoiceTreeFilterValue
									key={idx}
									index={value}
									filter={filter}
									filterValues={filterValues}
									filterStatus={filterStatus}
									matchingFilters={matchingFilters}
									showFilterCode={filter.getIn([
										'visKode',
										Math.max(filterValues.getIn([value, 'nivaa']) - 1, 0),
									])}
									setActiveTabState={setActiveTabState}
									hasActiveTabState={hasActiveTabState}
									activeSearch={activeSearch}
									selectAllHandler={selectAllHandler}
									selectAllSubLevelHandler={selectAllSubLevelHandler}
									showChooseAll={filter.get('visVelgAlle')}
									showChooseAllSubLevel={filter.get('visVelgAlleUnder')}
									shouldExpand={false}
									selection={selection}
									toggleFilterSelection={toggleFilterSelection}
								    overrideExpandLevel={overrideExpandLevel}
								/>
							))}
						</ul>
					)}
				</li>
			)
		}
		const levelClass = this.searchIsActive() ? '' : 'last-level'
		return <li className={levelClass}>{this.renderInput()}</li>
	}
}
