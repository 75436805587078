import React, { Component } from 'react'
import { object, func } from 'prop-types'
import SingleChoiceFilterValue from './SingleChoiceFilterValue'
import FilterSearch from '../filter-search/FilterSearch'

export default class SingleChoiceFilter extends Component {
	static propTypes = {
		filter: object.isRequired,
		filterValues: object.isRequired,
		setFilterSelection: func,
		searchForString: func.isRequired,
		filterStatus: object,
		selection: object
	}

	constructor(props) {
		super(props)

		this.state = {
			activeSearch: '',
			matchingFilters: []
		}
	}

	searchHandler = searchtext => {
		if (searchtext === '') {
			this.setState({
				activeSearch: '',
				matchingFilters: []
			})

			return false
		}

		const { filterValues, filter } = this.props

		const matchingFilters = this.props.searchForString(
			searchtext,
			filterValues,
			true,
			filter.get('visKode')
		)

		if (matchingFilters) {
			this.setState({
				activeSearch: searchtext.toLowerCase(),
				matchingFilters
			})
		}
	}

	render() {
		const { filter, filterValues, setFilterSelection, filterStatus, selection } = this.props
		const { activeSearch, matchingFilters } = this.state

		let filterElements = filterValues

		if (activeSearch) {
			filterElements = filterValues.filter(val => matchingFilters.includes(val.get('indeks')))
		}

		return (
			<div className="nssReactApp">
				{filter.get('visSoekefelt') && (
					<FilterSearch
						searchHandler={this.searchHandler}
						placeholder={`Søk etter ${filter.get('navn').toLowerCase()}`}
					/>
				)}
				<div className="filter-values">
					{filterElements.map((value, idx) => (
						<SingleChoiceFilterValue
							key={idx}
							filterId={filter.get('id')}
							filterValue={value}
							selected={selection && selection.get(value.get('id'))}
							active={
								filter.get('sjekkFilterStatus')
									? filterStatus && filterStatus.includes(value.get('id'))
									: true
							} 
							filterChangeHandler={event =>
								setFilterSelection(filter.get('id'), event.currentTarget.value)
							}
						/>
					))}
				</div>
			</div>
		)
	}
}
