import { combineReducers } from 'redux'
import activeSelection from './activeSelection'
import filterActive from './filterActive'
import filterPanelMinimized from './filterPanelMinimized'
import filterSpec from './filterSpec'
import filterStatus from './filterStatus'
import filterSticky from './filterSticky'
import filterValues from './filterValues'
import showHiddenData from './showHiddenData'
import shownFilters from './shownFilters'
import pageData from './pageData'
export default combineReducers({
	activeSelection,
	filterActive,
	filterPanelMinimized,
	filterSpec,
	filterStatus,
	filterSticky,
	filterValues,
	showHiddenData,
	shownFilters,
	pageData
})
