import { Map } from 'immutable'
import { setText } from '../../ducks/text'
import Store from '../../../../../store'
export const types = {
	REQUEST_FILTER_SPEC: 'REQUEST_FILTER_SPEC',
	RECEIVE_FILTER_SPEC: 'RECEIVE_FILTER_SPEC',
	RECEIVE_FILTER_SPEC_ERROR: 'RECEIVE_FILTER_SPEC_ERROR'
}

const initialState = Map({
	isFetching: false,
	items: null,
	error: null
})

export default (state = initialState, action) => {
	switch (action.type) {
		case types.REQUEST_FILTER_SPEC:
			return state.set('isFetching', true)
		case types.RECEIVE_FILTER_SPEC:
			return state.merge({ isFetching: false, items: action.filterSpec })
		case types.RECEIVE_FILTER_SPEC_ERROR:
			return state.merge({ isFetching: false, error: action.error })
		default:
			return state
	}
}

export const requestFilterSpec = () => ({
	type: types.REQUEST_FILTER_SPEC
})

export const receiveFilterSpec = filterSpec => ({
	type: types.RECEIVE_FILTER_SPEC,
	filterSpec
})

export const receiveFilterSpecError = error => ({
	type: types.RECEIVE_FILTER_SPEC_ERROR,
	error
})

export const fetchFilterSpec = () => (dispatch, getState) => {
			const state = Store.getState()
			let filterSpec = state.filter.pageData.filterSpecResponse
			dispatch(receiveFilterSpec(filterSpec.filterSpec))
			dispatch(setText(filterSpec.tekster))
			
		}


