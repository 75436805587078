import React, { Component } from 'react'
import { func, string, node } from 'prop-types'
import { Accordion, FlexColumn, FlexRow } from '@utdanningsdirektoratet/lisa'

export default class FilterHeader extends Component {
	static propTypes = {
		toggleShowFilter: func,
		filterName: string,
		children: node,
	};

	toggleShowFilterHandler = () => {
		this.props.toggleShowFilter()
	};

	render() {
		const { filterName, children} = this.props

		return (
			<div onToggle={this.toggleShowFilterHandler}>
				<Accordion
					className='border-bottom'
					title={
						<FlexRow className="mt-8 mr-8 ml-8">
							<FlexColumn>{filterName}</FlexColumn>
							<FlexColumn>{children[0]}</FlexColumn>
						</FlexRow>
					}
				>
					<div className="filter-accordion-content">{children[1]}</div>
				</Accordion>
			</div>
		)
	}
}