import React from 'react'
import { object, func } from 'prop-types'
import Checkbox from '../../form/Checkbox'

const ToggleFilter = ({ selection, filter, setFilterSelection, children }) => {
	const onChangeHandler = event => {
		const filterValue = event.target.checked ? '1' : '0'
		setFilterSelection(filter.get('id'), filterValue)
	}

	const checked = selection && selection.first()

	return (
		<div className="toggleFilter">
			<Checkbox checked={Boolean(checked)} value={filter.get('id')} onChange={onChangeHandler}>
				{filter.get('navn')}
			</Checkbox>
			{children}
		</div>
	)
}

ToggleFilter.propTypes = {
	filter: object.isRequired,
	setFilterSelection: func,
	selection: object
}

export default ToggleFilter
