import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./../../../App.css";
import TableStats from "./TableStats/TableStats";
import Banner from "./Banner/Banner";
import FilterURL from "./FilterURL/FilterURL";
import { setBase, setFormat, setLoadingBase, setLoadingFormat } from "./FilterOptions/ducks/filter/pageData";
import axios from "axios";
import { Container } from '@utdanningsdirektoratet/lisa';
import FilterResult from "./FilterResult/FilterResult";
import { useUrl } from "./../../hooks/useUrl";
import NumberIcon from "./../../../../src/components/numberIcon/NumberIcon";
import { Alert, AlertTitle } from "@mui/material";

function App() {
  const dispatch = useDispatch();
  const baseUrl = useUrl("")
  const urlFormat = useUrl("format")
  const selectedTable = useSelector(state => state.filter.pageData.selectedTable)
  const [baseResponseError, setBaseResponseError] = useState(null);
  const [formatResponseError, setFormatResponseError] = useState(null);

  const getBaseResponse = async () => {
    dispatch(setLoadingBase(true))
    try {
      const response = await axios.get(baseUrl)
      dispatch(setBase(response.data));
      setBaseResponseError(null)

    } catch (error) {
      setBaseResponseError(error)
  }
  dispatch(setLoadingBase(false))
  };

  const getFormatResponse = async () => {
    dispatch(setLoadingFormat(true))
    try {
      const response = await axios.get(urlFormat)
      dispatch(setFormat(response.data));
     setFormatResponseError(null)

    } catch (error) {
      setFormatResponseError(error)
    }
    dispatch(setLoadingFormat(false))
  };

  useEffect(() => {
    getBaseResponse();
    getFormatResponse();
  }, []);

  return (
    <Container margin={{x:"auto"}} maxWidth="1400px"> 
      {(baseResponseError) &&
        <Alert className="mb-8" severity="error">
          <AlertTitle>API-kallet feilet {baseResponseError.code !== "ERR_NETWORK" && <>- Statuskode {baseResponseError.response.status} ({baseResponseError.response.statusText})</>}</AlertTitle> 
          GET {baseResponseError.config.url}
        </Alert>
      }
      {(formatResponseError) && 
        <Alert severity="error">
          <AlertTitle>API-kallet feilet {formatResponseError.code !== "ERR_NETWORK" && <>- Statuskode {formatResponseError.response.status} ({formatResponseError.response.statusText})</>}</AlertTitle> 
          GET {formatResponseError.config.url}
        </Alert>
      }
      {(!baseResponseError && !formatResponseError) && <>
        <Banner/>

        <NumberIcon className="mt-20" number ="1" label="Velg tabell"></NumberIcon>
        
        <TableStats />

        <NumberIcon className="mt-20" number ="2" label="Tilpass datautvalg"></NumberIcon>
        { selectedTable != null && <FilterURL/> }

        <NumberIcon className="mt-20" number ="3" label="Test datautvalg"></NumberIcon>
        { selectedTable != null && <FilterResult />}
      </> 
      } 
    </Container>
  );
}

export default App;
