export const types = {
	TOOGLE_FILTER_STICKY: 'TOOGLE_FILTER_STICKY'
}

export default (state = false, action) => {
	switch (action.type) {
		case types.TOOGLE_FILTER_STICKY:
			return !state
		default:
			return state
	}
}

export const toggleFilterSticky = () => ({
	type: types.TOOGLE_FILTER_STICKY
})
