const types = {
	TOGGLE_FILTER_PANEL: 'TOGGLE_FILTER_PANEL'
}

export default (state = false, action) => {
	switch (action.type) {
		case types.TOGGLE_FILTER_PANEL:
			return !state
		default:
			return state
	}
}

export const toggleFilterPanel = () => ({
	type: types.TOGGLE_FILTER_PANEL
})
