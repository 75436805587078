import React, { PureComponent } from 'react'
import { oneOf, bool, string, func, any } from 'prop-types'
import LisaTooltip from '../../../../../components/listaTooltip/LisaTooltip'

export default class ToolTip extends PureComponent {
	static propTypes = {
		textId: string,
		textKey: string,
		text: any,
		textIsFetching: bool,
		/** Name of node, used for aria label. */
		name: string.isRequired,
		/** Rendered in filter or table area. */
		type: oneOf(['filter', 'table']).isRequired,
		icon: oneOf(['help', 'brudd']).isRequired,
		tooltipIsActive: bool.isRequired,
		getTextById: func.isRequired,
		removeActiveTextKey: func.isRequired
	}

	clickHandler = event => {
		if (event.type === 'keydown') {
			if (event.key !== 'Tab' && event.key !== 'Enter' && event.key !== ' ') return false
		}
		const { textId, textKey, tooltipIsActive, removeActiveTextKey, getTextById } = this.props
		
		if (event.key === 'Tab') {
			return removeActiveTextKey()
		} 
		
		event.preventDefault()
		event.stopPropagation()
		return tooltipIsActive ? removeActiveTextKey() : getTextById(textId, textKey)
	}

	render() {
		const {
			text,
		} = this.props

		return (
			<LisaTooltip noMargin content={text}/>
		)
	}
}
