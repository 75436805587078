import React, { useState } from 'react'
import { Accordion, Button, Icon } from '@utdanningsdirektoratet/lisa'
import { setChosenDatasett, setChosenDatasettFilters} from "../../app/views/ApiKonsoll/FilterOptions/ducks/filter/pageData"
import { useDispatch } from 'react-redux';

function VelgDatasett({ className, content}) {
    const dispatch = useDispatch();
    const [selectedItem, setSelectedItem] = useState(null);

    const handleItemClick = (item) => {
        dispatch(setChosenDatasett(item));
        dispatch(setChosenDatasettFilters([]));
        setSelectedItem(item);
      };

    return (
        <div className={`${className}`}>
            {content.map((itemRegister) => (                     
                <Accordion 
                    key={itemRegister.Register}
                    className="border-bottom mt-12"
                    initialOpen={true}
                    title={itemRegister.Register}>
                    <div className='flex-column'>
                        {itemRegister.FaktaListe.map((itemFakta) => (
                            <Button 
                                key={itemFakta.Fakta}
                                className={selectedItem === itemFakta ? "text-colore-blue text-overflow" : ""} 
                                variant={"text"} 
                                onClick={() => handleItemClick(itemFakta)}
                            >
                                {itemFakta.Fakta}
                                {( selectedItem === itemFakta ) && <Icon className='ml-8' type="arrowThick" direction="right"/>}
                            </Button>

                        ))}
                    </div>
                </Accordion>
            ))}
        </div>
    );
}

export default VelgDatasett