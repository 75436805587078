import { connect } from 'react-redux'
import { toggleShowFilter } from '../../ducks/filter/shownFilters'
import { fetchFilterStatus } from '../../ducks/filter/filterStatus'
import FilterHeader from './FilterHeader'

const mapStateToProps = (state, ownProps) => ({
	filterName: ownProps.filter.get('navn'),
	showFilter: state.filter.shownFilters.get(ownProps.filter.get('id')),
})

const mapDispatchToProps = (dispatch, ownProps) => {
	const { filter } = ownProps
	
	return {
		toggleShowFilter: () => {
			dispatch(toggleShowFilter(filter.get('id')))
			dispatch(fetchFilterStatus(filter.get('id'), false))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterHeader)
