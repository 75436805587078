import React from 'react'
import { func, object, string } from 'prop-types'
import Checkbox from '../../form/Checkbox'

FilterSelectAll.propTypes = {
	filterId: string,
	filterValues: object,
	selection: object,
	selectAllHandler: func
}

export default function FilterSelectAll({ filterId, filterValues, selection, selectAllHandler }) {
	if (!filterValues.size) return false

	return (
		<div className="nssReactApp mb-12">
			<Checkbox
				onChange={event => selectAllHandler(filterId, filterValues, event.target.checked)}
				checked={(selection === undefined)? false : (selection.size === filterValues.size && filterValues.size > 0)}
			>
				Velg alle
			</Checkbox>
		</div>
	)
}
