import React from 'react'
import Checkbox from '../../form/Checkbox'

export default function TreeFilterSelectAllSubLevel({
	filterId,
	filterValues,
	selectAllSubLevelHandler,
	matchingFilters,
	activeSearch,
	selection,
	filterValuesToRender,
	dashedLine
}) {
	const style = { marginLeft: '29px' }

	const selectAllSubLevelIndexes = activeSearch ? matchingFilters : filterValuesToRender

	const selectAllSubLevelFilterValues = selectAllSubLevelIndexes
		.filter(index => {
			// Finn IDer til filtre som skal settes....
			if (index === 0) {
				return filterValues.getIn([index, 'erSkjult']) // Om alle niv�et skal taes med
			}
			return true
		})
		.map(index => filterValues.get(index))

	let checkedButton = false

	let children = []
	let childrenTot = []

	if (selection !== undefined) {
		const selectedFilterValues = selectAllSubLevelFilterValues.filter(value => selection.includes(value.get('id')))
		let i = 0
		let indikator = selectedFilterValues[i]

		while (indikator && !indikator.isEmpty()) {
			if (indikator.get('barn') !== undefined) {
				let branchChildren = []
				let branchTotChildren = []
				let filterBranchChildren = indikator.get('barn')._tail.array

				if (indikator.get('barn')._root !== null) {
					filterBranchChildren = filterBranchChildren.concat(indikator.get('barn')._root.array[0].array)
				}

				if (activeSearch) {
					let j
					for (j = 0; j < filterBranchChildren.length; j++) {
						let jj
						for (jj = 0; jj < selectedFilterValues.length; jj++) {
							if (filterBranchChildren[j] === selectedFilterValues[jj]._root.entries[0][1]) {
								branchChildren[branchChildren.length] = filterBranchChildren[j]
							}
						}
						for (jj = 0; jj < selectAllSubLevelFilterValues.length; jj++) {
							if (filterBranchChildren[j] === selectAllSubLevelFilterValues[jj]._root.entries[0][1]) {
								branchTotChildren[branchTotChildren.length] = filterBranchChildren[j]
							}
						}
					}
				}
				else {
					branchChildren = filterBranchChildren
					branchTotChildren = branchChildren
				}

				if (children !== undefined) { children = children.concat(branchChildren) }
				else { children = branchChildren }
				if (childrenTot !== undefined) { childrenTot = childrenTot.concat(branchTotChildren) }
				else { childrenTot = branchTotChildren }

			}
			i += 1;
			indikator = selectedFilterValues[i]
		}

		if (!selection || !selection.size) {
			checkedButton = false
		} else if (activeSearch) {
				checkedButton = (childrenTot.length === children.length)
		} else {
			checkedButton =
				(selection.size === (children.length + selectedFilterValues.length))
		}
	}
	if (!selectAllSubLevelFilterValues.length) return false // Ingen verdier, vis ikke velg alle knapp

	return (
		<div className="nssReactApp mb-12 text-overflow">
			<Checkbox
				checked={checkedButton}
				onChange={event => selectAllSubLevelHandler(filterId, selectAllSubLevelFilterValues, event.target.checked)}
			>
				{activeSearch ? 'Velg alle underliggende' : 'Velg alle underliggende'}
			</Checkbox>
		</div>
	)
}
