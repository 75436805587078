import React, { Component } from 'react'
import { object, func } from 'prop-types'
import SingleChoiceTreeFilterValue from './SingleChoiceTreeFilterValue'
import FilterSearch from '../filter-search/FilterSearch'

export default class SingleChoiceTreeFilter extends Component {
	static propTypes = {
		filter: object.isRequired,
		filterValues: object.isRequired,
		filterStatus: object,
		selection: object,
		setFilterSelection: func,
	}

	constructor(props) {
		super(props)
		this.state = {
			activeSearch: '',
			matchingFilters: [],
			activeTabStateId: null,
		}
	}

	setActiveTabState = (id) => {
		this.setState({ activeTabStateId: id })
	}

	searchHandler = (searchtext) => {
		if (searchtext === '') {
			this.setState({
				activeSearch: '',
				matchingFilters: [],
			})

			return false
		}

		const { filterValues, filter } = this.props

		const matchingFilters = this.props.searchForString(
			searchtext,
			filterValues,
			true,
			filter.get('visKode')
		)

		if (matchingFilters) {
			this.setState({
				activeSearch: searchtext.toLowerCase(),
				matchingFilters,
			})
		}
	}

	hasActiveTabState = (id) => id === this.state.activeTabStateId

	render() {
		const { filter, filterValues, filterStatus, selection, setFilterSelection } = this.props
		const { activeSearch, matchingFilters } = this.state

		if (!filterValues) return false

		const rootNode = filterValues.get(0)
		const filterValuesToRender =
			rootNode.get('skjult') === 1 ? rootNode.get('barn') : [rootNode.get('indeks')]

		return (
			<div className="nssReactApp">
				{filter.get('visSoekefelt') && (
					<FilterSearch
						searchHandler={this.searchHandler}
						placeholder={`Søk etter ${filter.get('navn').toLowerCase()}`}
					/>
				)}
				<div className="filter-values tree-filter">
					<ul className="no-bullet">
						{filterValuesToRender.map((value, idx) => (
							<SingleChoiceTreeFilterValue
								key={idx}
								index={value}
								filter={filter}
								filterValues={filterValues}
								filterStatus={filterStatus}
								matchingFilters={matchingFilters}
								showFilterCode={filter.getIn([
									'visKode',
									Math.max(filterValues.getIn([value, 'nivaa']) - 1, 0),
								])}
								setActiveTabState={this.setActiveTabState}
								hasActiveTabState={this.hasActiveTabState}
								activeSearch={activeSearch}
								selected={Boolean(selection && selection.get(filterValues.getIn([value, 'id'])))}
								selection={selection}
								setFilterSelection={setFilterSelection}
							/>
						))}
						{activeSearch && !matchingFilters.length && (
							<div>
								<i>Søket ditt ga ingen treff</i>
							</div>
						)}
					</ul>
				</div>
			</div>
		)
	}
}
