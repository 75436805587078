import React, { Fragment } from 'react'
import { object, bool, any, func, node } from 'prop-types'

Checkbox.propTypes = {
	style: object,
	checked: bool,
	value: any,
	onChange: func,
	disabled: bool,
	children: node,
	notClickable: bool
}

export default function Checkbox({
	style,
	checked,
	value,
	onChange,
	disabled,
	children,
	notClickable = false
}) {
	return (
		<div className="checkbox">
			<label style={style}> 
				{!notClickable && (
					<Fragment>
						<input
							className="sr-only"
							type="checkbox"
							checked={checked}
							value={value}
							onChange={onChange}
							disabled={disabled}
						/>

						<span className="cr">
							<i className="cr-icon fa fa-check" />
						</span>
					</Fragment>
				)}

				<span className="filter-label-name">{children}</span>
			</label>
		</div>
	)
}
