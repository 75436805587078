import { Map, fromJS } from 'immutable'

export const types = {
	REQUEST_FILTER_VALUES: 'REQUEST_FILTER_VALUES',
	RECEIVE_FILTER_VALUES: 'RECEIVE_FILTER_VALUES',
	RECEIVE_FILTER_VALUES_ERROR: 'RECEIVE_FILTER_VALUES_ERROR'
}

const initialState = Map({
	isFetching: false,
	items: null,
	error: null
})

export default (state = initialState, action) => {
	switch (action.type) {
		case types.REQUEST_FILTER_VALUES:
			return state.set('isFetching', true)
		case types.RECEIVE_FILTER_VALUES:
			return state.merge({ isFetching: false, items: fromJS(action.filterValues) })
		case types.RECEIVE_FILTER_VALUES_ERROR:
			return state.merge({ isFetching: false, error: action.error })
		default:
			return state
	}
}

export const requestFilterValues = () => ({
	type: types.REQUEST_FILTER_VALUES
})

export const receiveFilterValues = filterValues => ({
	type: types.RECEIVE_FILTER_VALUES,
	filterValues
})

export const receiveFilterValuesError = error => ({
	type: types.RECEIVE_FILTER_VALUES_ERROR,
	error
})
