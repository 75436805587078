import React from 'react'
import Radiobutton from '../../form/RadioButton'
import { useSelector } from 'react-redux'

const SingleChoiceFilterValue = ({
	filterValue,
	filterId,
	selected,
	filterChangeHandler,
	active
}) => {
	
	const labelStyle = {
		color: active ? '' : '#ccc'
	}
	const onlyOneTidID = useSelector(state => state.filter.pageData.onlyOneTidID)

	return (
		<Radiobutton
			style={labelStyle}
			value={filterValue.get('id')}
			checked={selected !== false && selected !== undefined}
			name={filterId}
			onChange={filterChangeHandler}
			disabled={!active || (filterValue.get('id')==onlyOneTidID)}
		>
			{filterValue.get('navn')}
		</Radiobutton>
	)
}

export default SingleChoiceFilterValue
