import { connect } from 'react-redux'
import {
	toggleMultiFilterSelection,
	setFilterSelection,
	toggleFilterSelectionAll,
	toggleFilterSelectionAllSubLevel
} from '../../ducks/filter/activeSelection'
import { fetchFilterStatus } from '../../ducks/filter/filterStatus'
import FilterType from './FilterType'

const mapStateToProps = (state, { filter }) => ({
	selection: state.filter.activeSelection.get(filter.get('id')),
	filterStatus: state.filter.filterStatus.get(filter.get('id')),
	filterValues: state.filter.filterValues.get('isFetching')
		? null
		: state.filter.filterValues.getIn(['items', filter.get('id')]),
	filterTextId: filter.get('tekstId_Beskrivelse')
})

const mapDispatchToProps = dispatch => ({
	setFilterSelection: (filterId, valueId) => {
		dispatch(setFilterSelection(filterId, parseInt(valueId)))
		dispatch(fetchFilterStatus(filterId))
	},
	toggleFilterSelection: (filterId, valueId, index, toggleMode) => {
		dispatch(toggleMultiFilterSelection(filterId, parseInt(valueId), index, toggleMode))
		dispatch(fetchFilterStatus(filterId))
	},
	toggleFilterSelectionAll: (filterId, valuesArray, checked) => {
		dispatch(toggleFilterSelectionAll(filterId, valuesArray, checked))
		dispatch(fetchFilterStatus(filterId))
	},
	toggleFilterSelectionAllSubLevel: (filterId, valuesArray, checked) => {
		dispatch(toggleFilterSelectionAllSubLevel(filterId, valuesArray, checked))
		dispatch(fetchFilterStatus(filterId))
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterType)
