import React from 'react'
import Checkbox from '../../form/Checkbox'

export default function TreeFilterSelectAll({
	filterId,
	filterValues,
	selectAllHandler,
	matchingFilters,
	activeSearch,
	selection,
	filterValuesToRender,
	dashedLine
}) {
	const style = { marginLeft: '29px' }

	const selectAllIndexes = activeSearch ? matchingFilters : filterValuesToRender

	const selectAllFilterValues = selectAllIndexes
		.filter(index => {
			// Finn IDer til filtre som skal settes....
			if (index === 0) {
				return filterValues.getIn([index, 'erSkjult']) // Om alle nivået skal taes med
			}
			return true
		})
		.map(index => filterValues.get(index))

	let checkedButton = false

	if (!selection || !selection.size) {
		checkedButton = false
	} else if (activeSearch) {
		checkedButton = (selection.size === (selectAllFilterValues.length + 1))
	} else {
		checkedButton =
			selectAllFilterValues.filter(value => selection.includes(value.get('id'))).length ===
			selectAllFilterValues.length // Selection.subtract(selectAllFilterValues.map(filter => filter.get('id')))
	}

	if (!selectAllFilterValues.length) return false // Ingen verdier, vis ikke velg alle knapp

	return (
		<div className="nssReactApp mb-12 text-overflow">
			<Checkbox
				checked={checkedButton}
				onChange={event => selectAllHandler(filterId, selectAllFilterValues, event.target.checked)}
			>
				{activeSearch ? 'Velg alle i søkeresultat' : 'Velg alle'}
			</Checkbox>
		</div>
	)
}
