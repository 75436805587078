import { Pre } from "./styles";
import React, {useEffect} from 'react'
import Prism from '../../util/prismjs/prism'
import "../../util/prismjs/prism.css";
import "./syntaxHighlight.css";
import { Container, CopyToClipBoard, Text  } from "@utdanningsdirektoratet/lisa";

//The Syntax Highlight contains boilerplate code, refer to: https://www.npmjs.com/package/prism-react-renderer if you want to customize even more.
function SyntaxHighlight({ label, code, language, noHighlight = false }) {
    useEffect(() => {
        if (!noHighlight) {
            Prism.highlightAll();
        }
    }, [code, language]);

    const block = (
        // Må ha div fordi position relative må settes utenfor Pre
        <div className="outlined-syntax-highlight copy-to-clipboard">
                <Pre aria-label={label} className="syntax-highlight">  
                    {noHighlight ?
                    //Bruker Text for at tekststørrelse skal justeres om man overstyrer i Chrome når man ikke har highlight av kode
                    <Text textColor={"#f8f8f2"}><code>{code}</code></Text> : 
                    <code className={language}>{code}</code>
                    }
                    <div className="copy-to-clipboard-icon">
                        <CopyToClipBoard copyToClipBoardText={code}></CopyToClipBoard>
                    </div>
                </Pre>  
        </div>
    )

    return noHighlight ? (
        // Uten highlight - må legges til styling av koden som skal vises. 
        <Container borderRadius="medium" className="syntax-styling" backgroundColor="#272822">
            {block}
        </Container>
    ) : 
        // Med automatisk syntax highlight 
        block
    ;
}
export default SyntaxHighlight
