import filterStringBuilder from './filterStringBuilder'

export default function urlBuilder(
	activeSelection,
	endpoint,
	optionalQueries = ''
) {
	if (activeSelection.isEmpty()) return endpoint

	const tempFilter = filterStringBuilder(activeSelection)
	return `${tempFilter}${optionalQueries}`
}