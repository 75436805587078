import { types } from './activeSelection'

export default (state = '', action) => {
	switch (action.type) {
		case types.TOGGLE_FILTER_SELECTION:
		case types.TOGGLE_FILTER_SELECTION_ALL:
		case types.SET_FILTER_SELECTION:
			return action.filterId
		default:
			return state
	}
}
