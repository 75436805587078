import { Map, Set } from 'immutable'
import { resetShownFilters } from './shownFilters'
import { fetchFilterStatus } from './filterStatus'
import { setSelectedFilter } from './pageData.js'
import { fromJSListToSet } from '../../util/immutableHelpers.js'

export const types = {
	TOGGLE_FILTER_SELECTION: 'TOGGLE_FILTER_SELECTION',
	TOGGLE_FILTER_SELECTION_ALL: 'TOGGLE_FILTER_SELECTION_ALL',
	TOGGLE_FILTER_SELECTION_ALL_SUB_LEVEL: 'TOGGLE_FILTER_SELECTION_ALL_SUB_LEVEL',
	SET_FILTER_SELECTION: 'SET_FILTER_SELECTION',
	SET_SELECTION: 'SET_SELECTION',
	RESET_INITIAL_STATE: 'RESET_INITIAL_STATE'
}

export default (state = Map(), action) => {
	switch (action.type) {
		case types.TOGGLE_FILTER_SELECTION:
			if (state.get(action.filterId)) {
				// Hvis filterId allerede finnes
				return state.get(action.filterId).has(action.valueId)
					? state.set(action.filterId, state.get(action.filterId).delete(action.valueId)) // Fjern hvis den finnes
					: state.set(action.filterId, state.get(action.filterId).add(action.valueId)) // Legg til hvis den ikke finnes
			}
			return state.set(action.filterId, Set([action.valueId])) // Opprett filteret i active state
		case types.TOGGLE_FILTER_SELECTION_ALL:
			return state.set(action.filterId, Set(action.valuesArray))
		case types.TOGGLE_FILTER_SELECTION_ALL_SUB_LEVEL:
			return state.set(action.filterId, Set(action.valuesArray))
		case types.SET_FILTER_SELECTION:
			if (action.valueId || action.valueId === 0) {
				return state.set(action.filterId, Set([action.valueId]))
			}
			return state.set(action.filterId, action.valueSet)
		case types.SET_SELECTION:
			return action.newSelection
		case types.RESET_INITIAL_STATE:
			return new Map()
		default:
			return state
	}
}
const toggleFilterSelectionActionCreator = (filterId, valueId) => ({
	type: types.TOGGLE_FILTER_SELECTION,
	filterId,
	valueId
})

export const toggleFilterSelection = (filterId, valueId) => (dispatch, getState) => {
	dispatch(toggleFilterSelectionActionCreator(filterId, valueId))
	dispatch(setSelectedFilter(getState().filter.activeSelection))
}

export const setSelection = newSelection => ({
	type: types.SET_SELECTION,
	newSelection
})
export const resetState = () => ({
	type: types.RESET_INITIAL_STATE,
})

const setFilterSelectionActionCreator = (filterId, valueId, valueSet) => ({
	type: types.SET_FILTER_SELECTION,
	filterId,
	valueId,
	valueSet
})

export const setFilterSelection = (filterId, valueId, valueSet) => (dispatch, getState) => {
	
	const filterVisAntallTilbud = getState().filter.activeSelection.get('VisAntallTilbud')
	const filterVisAndelTilbud = getState().filter.activeSelection.get('VisAndelTilbud')
	const filterVisAntallKarakterer = getState().filter.activeSelection.get('VisAntallKarakterer')
	const filterVisSnittkarakter = getState().filter.activeSelection.get('VisSnittkarakter')
	const filterVisKarakterfordeling = getState().filter.activeSelection.get('VisKarakterfordeling')

	if (filterVisAntallTilbud !== undefined && filterVisAndelTilbud !== undefined) {
		const VisAntallTilbud = filterVisAntallTilbud._map._root.entries[0][0]
		const VisAndelTilbud = filterVisAndelTilbud._map._root.entries[0][0]
		if ((VisAntallTilbud + VisAndelTilbud + valueId) > 1 || (filterId !== 'VisAntallTilbud' && filterId !== 'VisAndelTilbud')) {
			dispatch(setFilterSelectionActionCreator(filterId, valueId, valueSet))
		}
	}
	else if (filterVisAntallKarakterer !== undefined && filterVisSnittkarakter !== undefined && filterVisKarakterfordeling !== undefined) {
		const VisAntallKarakterer = filterVisAntallKarakterer._map._root.entries[0][0]
		const VisSnittkarakter = filterVisSnittkarakter._map._root.entries[0][0]
		const VisKarakterfordeling = filterVisKarakterfordeling._map._root.entries[0][0]
		if ((VisAntallKarakterer + VisSnittkarakter + VisKarakterfordeling + valueId) > 1 || (filterId !== 'VisAntallKarakterer' && filterId !== 'VisSnittkarakter' && filterId !== 'VisKarakterfordeling')) {
			dispatch(setFilterSelectionActionCreator(filterId, valueId, valueSet))
		}
	}
	else {
		dispatch(setFilterSelectionActionCreator(filterId, valueId, valueSet))
	}
	dispatch(setSelectedFilter(getState().filter.activeSelection))
}

const toggleFilterSelectionAllActionCreator = (filterId, valuesArray) => ({
	type: types.TOGGLE_FILTER_SELECTION_ALL,
	filterId,
	valuesArray
})

const toggleFilterSelectionAllSubLevelActionCreator = (filterId, valuesArray) => ({
	type: types.TOGGLE_FILTER_SELECTION_ALL_SUB_LEVEL,
	filterId,
	valuesArray
})

export const toggleFilterSelectionAll = (filterId, valuesArray, checked) => (dispatch, getState) => {
	valuesArray = valuesArray.map(value => value.get('id'))
	if (!checked) valuesArray = [] // uncheck box, remove all
	else {
		let filterValues = getState().filter.filterValues.getIn(['items', filterId])
		let nodesArrayParentId = filterValues.filter(node => valuesArray.find(x => x == node.get('id'))).map(val => val.get('forelder'))._tail.array

		let i = 0
		for (i = 0; i < nodesArrayParentId.length; i++) {
			if (nodesArrayParentId[i] !== undefined)
				if (valuesArray.find(e => e == filterValues.get(nodesArrayParentId[i])._root.entries[1][1]) == undefined)
					valuesArray.push(filterValues.get(nodesArrayParentId[i])._root.entries[1][1])
		}
	}
	dispatch(toggleFilterSelectionAllActionCreator(filterId, valuesArray))
	dispatch(setSelectedFilter(getState().filter.activeSelection))
}

export const toggleFilterSelectionAllSubLevel = (filterId, valuesArray, checked) => (dispatch, getState) => {
	const activeSelection = getState().filter.activeSelection.get(filterId)
	const filterValues = getState().filter.filterValues.getIn(['items', filterId])

	let children = []
	let i = 0
	let selected = valuesArray.filter(value => activeSelection.includes(value.get('id')))

	const activeSearch = (valuesArray.filter(value => value.get('id') > 0).length > 0)
	let childrenID = []

	if (activeSearch) {
		const selectedParents = selected.filter(value => value.get('id') < 0).map(value => value.get('indeks'))
		childrenID = valuesArray.filter(value => value.get('id') > 0 && selectedParents.includes(value.get('forelder'))).map(value => value.get('id'))
	}
	else {
		while (selected[i] && !selected[i].isEmpty()) {
			if (selected[i].get('barn') !== undefined) {
				let branchTotChildren = []
				if (selected[i].get('barn')._root !== null) {
					branchTotChildren = selected[i].get('barn')._root.array[0].array

					if (children !== undefined) { children = children.concat(branchTotChildren) }
					else { children = branchTotChildren }
				}

				branchTotChildren = selected[i].get('barn')._tail.array

				if (children !== undefined) { children = children.concat(branchTotChildren) }
				else { children = branchTotChildren }

			}
			i++
		}

		i = 0
		while (children !== undefined && children[i] && !children[i].isEmpty) {
			if (filterValues.get(children[i]).get('id') !== undefined) {
				childrenID[i] = filterValues.get(children[i]).get('id')
			}
			i++
		}
	}

	selected = selected.map(value => value.get('id'))
	if (checked) {
		selected = selected.concat(childrenID)
	}
	else {
		selected = selected.filter(x => x < 0)
	}

	dispatch(toggleFilterSelectionAllSubLevelActionCreator(filterId, selected))
	dispatch(setSelectedFilter(getState().filter.activeSelection))
}

export const resetFilterSelection = () => (dispatch, getState) => {
	const { filter } = getState()
   
	//Litt hacky løsning fordi Accordion ikke lar oss styre om den er åpen eller lukket. 
	var detailsElements = document.getElementById("accordion-filter").getElementsByTagName("details");

	for (var i = 0; i < detailsElements.length; i++) {
	  detailsElements[i].open = false;
	}

	dispatch(setSelection(fromJSListToSet(filter.pageData.defaultUttrykk)))
	dispatch(setSelectedFilter(getState().filter.activeSelection))
	dispatch(resetShownFilters())
}

// den er valgt, og skal derfor deselectes og alle barna skal også deselectes
export function deselectSelfAndChildren(valueId, valueNode, activeSelection, filterValues) {
	activeSelection = activeSelection.delete(valueId)

	let children = valueNode.get('barn')

	while (children && !children.isEmpty()) {
		const child = children.last()
		children = children.pop()
		
		activeSelection = activeSelection.delete(filterValues.getIn([child, 'id']))
		const nextChildren = filterValues.getIn([child, 'barn'])

		if (nextChildren) {
			children = children.concat(nextChildren)
		}
	}
	return activeSelection
}

export function selectSelfAndParents(valueId, valueNode, activeSelection, filterValues) {
	activeSelection = activeSelection.add(valueId) // legg til seg selv først

	let parentNode = filterValues.get(valueNode.get('forelder'))

	while (parentNode) {
		const currentNode = parentNode // eksplisitt at dette er currentNode vi sjekker

		if (currentNode.get('skjult') !== 1 && !activeSelection.has(currentNode.get('id'))) {
			activeSelection = activeSelection.add(currentNode.get('id'))
		}

		parentNode = filterValues.get(currentNode.get('forelder')) // get new parent
	}
	return activeSelection
}

export const toggleMultiFilterSelection = (filterId, valueId, index, toggleMode) => (
	dispatch,
	getState
) => {
	if (!filterId) return false

	if (toggleMode !== 'sti') return dispatch(toggleFilterSelection(filterId, valueId))

	const { filter } = getState()
	const { filterValues, activeSelection } = filter

	// Edgecase, tomt Set hvos filteret ikke er satt i activeSelection
	// (skjer kun hvis filteret ikke er definert i defaultVerdiene)
	let filterActiveSelection = activeSelection.get(filterId) || Set()
	const currentNode = filterValues.getIn(['items', filterId, index])

	filterActiveSelection = filterActiveSelection.has(valueId)
		? deselectSelfAndChildren(
			valueId,
			currentNode,
			filterActiveSelection,
			filterValues.getIn(['items', filterId])
		)
		: selectSelfAndParents(
			valueId,
			currentNode,
			filterActiveSelection,
			filterValues.getIn(['items', filterId])
		)

	return dispatch(setFilterSelection(filterId, null, filterActiveSelection))
}
