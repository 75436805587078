import { baseURL } from '../../util/config'
import { useSelector } from "react-redux"

export const useUrl = (type) => {
    const selectedTable = useSelector(state => state.filter.pageData.selectedTable)
    const selectedFilterId = useSelector(state => state.filter.pageData.selectedFilterId)
    const selectedFilter = useSelector(state => state.filter.pageData.selectedFilter)
    const selectedFormat = useSelector(state => state.filter.pageData.selectedFormat)
    const selectedPage = useSelector(state => state.filter.pageData.selectedPage)
    let table = ""
    let filterId = ""
    let filter = ""
    let format = ""
    let page = ""
    
    if (!(type === "" || type === "format")) {
        table = selectedTable
    }

    if (type === "/filterStatus" && selectedFilter != null) {
        filterId = `?filterId=${selectedFilterId}`
        filter = `&filter=${selectedFilter}`
    }

    if ((type === "/data" || type === "/sideData") && selectedFilter != null) {
        filter = `?filter=${selectedFilter}`
    }

    if (selectedFilter == null) {
        filter = ""
    }

    if (type === "/data") {
        format = `&format=${selectedFormat}`
    }

    if (type === "/data") {
        page = `&sideNummer=${selectedPage}`
    }

    return baseURL + table + type + filterId + filter + format + page
}