import React from "react";
import "./App.css";
import { Container, Footer, H1, TabContainer, TabItem, Text } from '@utdanningsdirektoratet/lisa';
import ApiKonsoll from "./app/views/ApiKonsoll/ApiKonsoll"
import Brukerveiledning from "./app/views/Brukerveiledning/Brukerveiledning";
import ApiDokumentasjon from "./app/views/ApiDokumentasjon/ApiDokumentasjon";
import Datasett from "./app/views/Datasett/Datasett";
import { setSelectedTab} from "./app/views/ApiKonsoll/FilterOptions/ducks/filter/pageData"
import { useDispatch, useSelector } from "react-redux";

function App() {
  const dispatch = useDispatch();
  const selectedTab = useSelector(state => state.filter.pageData.selectedTab)

  const padding = {
    right: 40,
    top: 12,
    bottom: 12, 
    left: 40,
  }

  const links = [
    {
      "text": "Om API-konsoll",
      "url": "https://www.udir.no/om-udir/data/api-data-fra-elevundersokelsen/"
    },
    {
      "text": "Kontakt",
      "url": "https://www.udir.no/om-udir/kontakt-oss/"
    },
    {
      "text": "Personvernerklæring",
      "url": "https://www.udir.no/om-udir/personvernerklaring-udir/#a151532"
    },
    {
      "text": "Tilgjengelighetserklæring",
      "url": "https://uustatus.no/nb/erklaringer/publisert/4e66fd47-9c83-4298-8a50-a0b3be7fb99f"
    },
  ]

  return (    
  <div className="background page-container">
    <div>
      <Container padding={padding} backgroundColor="stålblå700">
      <H1 className="text-overflow mb-0" textColor="hvit">API-konsoll for Statistikkbanken</H1>
      </Container>
      <Container className="centerContent" backgroundColor="hvit">
        <TabContainer selectedId={selectedTab} onChange={(tab) => dispatch(setSelectedTab(tab.id))}>
          <TabItem id={0} label="API-konsoll" />
          <TabItem id={1} label="Hele datasett" />
          <TabItem id={2} label="Brukerveiledning" />
          <TabItem id={3} label="Dokumentasjon" />
        </TabContainer>
      </Container>
      {/* NOTE! Hvorfor bruker vi ikke TabItem? For å lagre det bruker har gjort istedenfor å laste inn komponenten på nytt når man bytter tab har vi valgt å kun skjule innholdet. */}
      <div className="tabItemContent" style={{ display: selectedTab === 0 ? "block" : "none" }}>
        <ApiKonsoll />
      </div>
      <div className="tabItemContent" style={{ display: selectedTab === 1 ? "block" : "none" }}>
        <Datasett/>
      </div>
      <div className="tabItemContent"  style={{ display: selectedTab === 2 ? "block" : "none" }}>
        <Brukerveiledning/>
      </div>
      <div className="tabItemContent"  style={{ display: selectedTab === 3 ? "block" : "none" }}>
        <ApiDokumentasjon/>
      </div>
    </div>
    <div>
      <Footer className="footer" aboutText="API-konsollen er levert av Utdanningsdirektoratet" externalLinks={links} versionNumber="1.0"/>
    </div>
  </div>
);
}

export default App;
