import { Container, FlexColumn, FlexRow, H1, H2, H3, Label, Link, Text} from '@utdanningsdirektoratet/lisa'
import SyntaxHighlight from '../../../components/syntaxHighlighter/SyntaxHighlight'
import imageEnhetsdimensjon from './assets/enhetsdimensjon.png'
import imageUtviklerdokumentasjon from './assets/utviklerdokumentasjon.png'
import GetIcon from '../../../components/getIcon/GetIcon'

function ApiDokumentasjon() {  

const handleClickScroll = (id) => {
  const element = document.getElementById(id);
  element.scrollIntoView();
};

return (
  <Container margin={{x : "auto"}} maxWidth="900px">
    <H1>Dokumentasjon</H1>
      <Text className="mb-40" textStyle='subtitle'>På denne siden finner du dokumentasjon av Statistikkbankens åpne API.</Text>
    
      <H2 className="boldOverride">Innholdsfortegnelse</H2>
      <ul className='mb-40'>
        <li className='mb-4'><Link onClick={() => handleClickScroll("dok-utviklerdokumentasjon")}>Utviklerdokumentasjon</Link></li>
        <li className='mb-4'><Link onClick={() => handleClickScroll("dok-dimensjonering")}>Dimensjonering av data</Link></li>
        <li className='mb-4'><Link onClick={() => handleClickScroll("dok-filteroppbygging")}>Oppbygging av filter-parametre</Link></li>
        <li className='mb-4'><Link onClick={() => handleClickScroll("dok-notasjon")}>Notasjon for filter-parameter i spørrestrengen</Link></li>
        <li><Link onClick={() => handleClickScroll("dok-kodeeksempel")}>Bruk av APIet med Python, R eller Javascript</Link></li>
      </ul>
    
    <H2 className="boldOverride" id="dok-introduksjon">Introduksjon til statistikkbankens eksport-API</H2>
      <Text>Utdanningsdirektoratets statistikkbank tilbyr et åpent API for egne spørringer mot statistikkbankens statistikker. Foreløpig omfatter dette åtte tabeller knyttet til Elevundersøkelsen: Indikator, Tema, Mobbing og Deltakelse for grunnskole, og tilsvarende tabeller for videregående.</Text>
      <Text className="mt-12">API-et tilgjengeliggjør data med en HTTP (Hypertext Transfer Protocol) GET-spørring. GET-spørringen er satt sammen av en webadresse og en spørrestreng.</Text>
      <Label className="mt-8" textStyle="bodyBold">Eksempel</Label>
      <SyntaxHighlight label="Kodeeksempel" language="language-javascript" code={"https://api.statistikkbanken.udir.no/api/rest/v2/Eksport/{tabellnummer}/data?filter=Fylkeskode(38)_Organisasjonsnummer(975280233)&format=0&sideNummer=1"}/>
      
        <Container className="mb-20 mt-12 shadow" borderRadius="small" backgroundColor='#CCE6D8' padding={{right: 40, top: 20, bottom: 20, left: 40,}}>
            <FlexRow valign="start" marginBetween={8}>
                <FlexColumn><Label textStyle="bodyBold">Merk:</Label></FlexColumn>
                <FlexColumn><Text>Filter-parameter har egen notasjon.</Text></FlexColumn>
            </FlexRow>
        </Container>

      <Text>For å dokumentere og tilrettelegge for bruk av det åpne API-et, tilbyr vi tjenesten API-konsollen. API-konsollen har en todelt funksjon og målgruppe: På den ene siden bistår den deg med å filtrere, hente ut og teste data. På den andre siden fungerer API-konsollen også som en dokumentasjon av API-et.</Text>
      <Text className="mt-12">I avsnittet 'Utviklerdokumentasjon' beskrives API-ets metoder, mens i avsnittet 'Oppbygging av filter-parameter' beskrives hvordan du kan bygge opp egne spørringer utenfor konsollen når du f.eks. lager egne script for uthenting av data. Det finnes flere muligheter for filtrering i det åpne API-et enn det som vises gjennom konsollen.</Text>

      <H3 className='boldOverride mt-20'>Skjuling av sensitive data </H3>
      <Text>Av hensyn til personvern, er data som kan spores tilbake til individer skjult, også kalt prikket. Datagrunnlaget er derfor ikke egnet for aggregering og Utdanningsdirektoratet (Udir) anbefaler at man bruker de aggregatene som ligger i dataene. Der data er prikket, erstattes verdier  med *.</Text>

      <H3 className='boldOverride mt-20'>{'Utformater - "response": {…}'}</H3>
      <Text>API-et kan returnere data i to formater:</Text> 
      <ul>
        <li>JSON (JavaScript Object Notation)</li>
        <li>CSV  (Comma-separated values)</li>
      </ul>

      <H3 className='boldOverride mt-20'>Statuskoder</H3>
      <Text>API-et bruker statuslinjeelementet av http-responsen til å informere deg om resultatet av spørringene dine. Følgende koder benyttes:</Text>
      <ul>
        <li>200 - Suksess</li>
        <li>400 - Ugyldig forespørsel, inkludert feil bruk av parametere</li>
        <li>500 - Serverfeil</li>
      </ul>

    <H2 className='boldOverride mt-40' id="dok-utviklerdokumentasjon">Utviklerdokumentasjon</H2>
      <Text className='mb-20'>Totalt er det seks GET-metoder som du kan benytte til å kommunisere med API-et. Disse metodene er beskrevet i API-konsollen under nedtrekksmenyene med tittel 'Utviklerdokumentasjon'. Hver metode vises med curl, url og resultat.</Text>
      
      <Container margin={{x : "auto"}} className="centerContent" maxWidth="600px">
          <img className="image-size" src={imageUtviklerdokumentasjon} alt="Et eksempel på hva man finner i API-konsollen når man ekspanderer en av nedtrekksmenyene under tittelen 'Utviklerdokumentasjon'. I eksempelet vises en ekspandert nedtrekksmeny med tittel 'GET Liste over tilgjengelige tabeller'. Inni den ekspanderte nedtrekksmenyen er det tilhørende kode i boksen med tittel Curl, samt i boksen med tittel URL-spørring. En tredje boks med tittelen Resultat inneholder et utsnitt av metadata man får ved å kalle metoden." />
      </Container>

      <div className='mt-20'>
      <Text textStyle="bodyBold"><GetIcon label="/rest/v2/Eksport/"/></Text>
      <Text>Returnerer en liste over tilgjengelige tabeller.</Text>
      </div>

      <div className='mt-20'>
      <Text textStyle="bodyBold"><GetIcon label="/rest/v2/Eksport/format"/></Text>
      <Text>Returnerer en liste av tilgjengelige formater.</Text>
      </div>

      <div className='mt-20'>
      <Text textStyle="bodyBold"><GetIcon label="/rest/v2/Eksport/{eksportId}/filterSpec"/></Text>
      <Text>Returnerer alle definerte filtre til en tabell. Bruk denne metoden for å finne navn på id-felt, kode-felt og nivå-felt (nivå brukes i hierarkiske dimensjoner, der 0 er topp-nivået).</Text>
      <Label className="mt-8" textStyle="bodyBold">Eksempel på response</Label>
      <SyntaxHighlight label="Kodeeksempel" language="language-json" code={`"EnhetID": {
"id": "EnhetID",
"feltnavnFilterKode": [
  "Nasjonaltkode",
  "Fylkekode",
  "Kommunekode",
  "Organisasjonsnummer"
],
"feltnavnNivaa": "EnhetNivaa"
},`}/>
      </div>

      <div className='mt-20'>
      <Text textStyle="bodyBold"><GetIcon label="/rest/v2/Eksport/{eksport Id}/filterVerdier"/></Text>
      <Text>Returnerer alle definerte filterverdier til en eksportversjon. For å finne id, kode og nivå verdier kan en kalle endepunktet filterVerdier.</Text>
      <Label className="mt-8" textStyle="bodyBold">Eksempel på response</Label>
      <SyntaxHighlight label="Kodeeksempel" language="language-json" code={`{
"indeks": 6,
"id": 28170,
"kode": "975283003",
"navn": "Birkenlund skole",
"nivaa": 4,
"forelder": 3
},`}/>
      </div>

      <div className='mt-20'>
      <Text textStyle="bodyBold"><GetIcon label="/rest/v2/Eksport/{eksportId}/filterStatus?filterId={filterId}"/></Text>
      <Text>Returnerer en liste over gyldig filtervalg for ett bestemt filter gitt valgte filterverdier.</Text>
      <Label className="mt-8" textStyle="bodyBold">Eksempel på response med filterId=TidID</Label>
      <SyntaxHighlight label="Kodeeksempel" language="language-json" code={`{
 "TidID": [
  202212,
  202112
 ]
}`}/>
      </div>

      <div className='mt-20'>
      <Text textStyle="bodyBold"><GetIcon label="/rest/v2/Eksport/{eksportId}/sideData"/></Text>
      <Text>Returnerer antall sider, rader og bytes for valgte filterverdier på datautvalget som vil bli returnert ved et kall til 'data'.</Text>
      <Label className="mt-8" textStyle="bodyBold">Eksempel på response</Label>
      <SyntaxHighlight label="Kodeeksempel" language="language-json" code={`[
{
  "Antall": 3,
  "JSONBytes": 1555,
  "JSONSider": 1,
  "JSONMaksRaderPrSide": 4,
  "CSVBytes": 857,
  "CSVSider": 1,
  "CSVMaksRaderPrSide": 4
}
]`}/>
      </div>

      <div className='mt-20'>
      <Text textStyle="bodyBold"><GetIcon label="/rest/v2/Eksport/{eksportId}/data"/></Text>
      <Text>Henter ut tabellens tilpassede datautvalg.</Text>
      </div>
      
    <H2 className='boldOverride mt-40' id="dok-dimensjonering">Dimensjonering av data</H2>
      <Text>Tabellene er organisert i dimensjoner. Eksempler på dimensjoner som er brukt er tid, kjønn, skoleår, fag med aggregering mot flere nivåer av faggrupperinger, og skoler med aggregering mot flere nivåer av geografiske grupperinger.</Text>
      <Container margin={{x : "auto"}} className="centerContent" maxWidth="600px">
        <figure style={{ marginLeft: "0px", marginRight:"0px"}}>
          <img className="image-size" src={imageEnhetsdimensjon} alt="Et eksempel på en oppbygging av geografiske enhetsdimensjoner med fire nivåer. Det øverste nivået er nasjonalt. Deretter er det en strek ned til neste nivå som er fylke. Fylke har eksemplene: Viken, Agder og Vestland. Nivået under der igjen er kommune der eksempelvis listes opp kommunene: Arendal, Kristiansand og Mandal med streker opp til Agder. Det nederste nivået er skole med eksemplene: Møvik, Flekkerøy og Tveit som har streker opp til Kristiansand kommune." />
          <figcaption>Figur 1 - Eksempel oppbygging av geografisk enhetsdimensjon med fire nivåer.</figcaption>
        </figure>
      </Container>
      <Text>Dimensjonene benyttes til filtrering av data. Ved uttrekk må du sende inn et obligatorisk filteruttrykk. På server blir filteruttrykket gjort om til et logisk uttrykk formatert som en SQL-spørring. Det logiske uttrykket vil kombinere oppgitte verdier for en dimensjon med OR operatorer slik at resultatet blir en union av det valgte verdiene. Velger du å filtrere på tre skoler i èn kommune vil det komme tre sett med tall. Tar du i tillegg med tall på kommunalt nivå vil du få aggregerte tall for alle skoler i kommunen og ikke bare aggregering for de tre skolene du har filtrert på. De ulike dimensjonene du filtrerer på omtales som delfilter.</Text>

    <H2 className='boldOverride mt-40' id="dok-filteroppbygging">Oppbygging av filter-parametre</H2>
      <Text>Dimensjoner kan filtreres på id, kode og nivå. Når du filtrerer på id så er den unik i motsetning til kode som også gjelder nedover i dimensjonsstrukturen. For eksempel kan du ved å filtrere på Fylkeskode få fylket, samt alle kommuner og skoler i fylket. Filtrerer du på EnhetID og oppgir en fylkeId får du bare data akkumulert for fylket.</Text>
      
      <Label className="mt-8" textStyle="bodyBold">Eksempel på filter for akkumulert nivå av Agder</Label>
      <SyntaxHighlight label="Kodeeksempel" language="language-javascript" code={"filter=EnhetID(-538)"}/>
      
      <Label className="mt-12" textStyle="bodyBold">Eksempel på filtrering av hele Agder med kommuner og skoler</Label>
      <SyntaxHighlight label="Kodeeksempel" language="language-javascript" code={"filter=Fylkekode(42)"}/>
      
      <Label className="mt-12" textStyle="bodyBold">Eksempel på filtrering av Agder med kommuner</Label>
      <SyntaxHighlight label="Kodeeksempel" language="language-javascript" code={"filter=EnhetNivaa(2_3)_Fylkekode(42)"}/>
      
      <Label className="mt-12" textStyle="bodyBold">Eksempel på filtrering av Birkenlund og Eydehavn skoler med aggregering på Arendal kommune, Agder fylke og nasjonalt</Label>
      <SyntaxHighlight label="Kodeeksempel" language="language-javascript" code={"filter=EnhetID(-12_-538_-720_28170_28474)"}/>
      
      <Label className="mt-12" textStyle="bodyBold">Eksempel på bruk av filter for filtrering på kjønn</Label>
      <SyntaxHighlight label="Kodeeksempel" language="language-javascript" code={"filter=EnhetID(-12_-538_-720_28170_28474)_KjoennID(-10)"}/>
      <Text className="mt-8 mb-12">Normalt vil man begrense søket ytterligere ved å angi for eksempel tidID, kjønn, trinn, eierform. Om du ikke er interessert i kjønn vil du kunne begrense resultatet ved å angi at du kun vil se på akkumulerte tall for begge kjønn for å slippe å se begge kjønn, gutt og jente. Når du da kombinerer enhetsfilteret med for eksempel kjønnsfilteret, blir det benyttet en logisk AND og du får redusert resultatet.</Text>
      
      <Label textStyle="bodyBold">Eksempel på hvordan serveren omformer eksempelet over til SQL-filter</Label>
      <SyntaxHighlight label="Kodeeksempel" language="language-sql" code={"WHERE EnhetID IN(-720,-538,-12) AND KjoennID IN(-10)"}/>
      <Text className="mt-8" >Bruk gjerne API-konsollen for å finne tabeller, sjekke filterspec eller filterverdier og teste datauttrekk med filtre basert på id-er. I API-konsollen blir filteruttrykket satt opp med default verdier for de ulike filtrene som ofte er lik at akkumulert 'alle' nivå. Dette reduserer datamengden og gjør resultatet lettere å forstå. Filteruttrykket er et godt utgangspunkt for videre dypdykk i de dimensjonene som er av interesse.</Text>

    <H2 className='boldOverride mt-40' id="dok-notasjon">Notasjon for filter-parameter i spørrestrengen</H2>
      <Text>Skilletegnet _ brukes mellom de ulike filtrene og mellom verdier i et filter. De ulike delfiltrene som benyttes i filteruttrykket må ordnes alfabetisk og bare tas med èn gang i filteruttrykket. Innenfor hvert delfilter oppgis èn eller flere verdier separert med _. Verdiene listes mellom parenteser: {'({v1}_{v2}_{v3})'}.</Text>
      <Text className="mt-12">I eksemplene så langt er det filtrert på enhetsdimensjonen og den logiske operatoren er OR i kombinasjon med AND om du bruker et nivåfilter og kjønn som er et annet delfilter.</Text>

      <Text className="mt-12">I eksemplene så langt er det filtrert på enhetsdimensjonen og den logiske operatoren er OR i kombinasjon med AND om du bruker et nivåfilter og kjønn som er et annet delfilter. Filteret: delfilter1{'('}v1_v2{')'}_delfilter2{'('}v10_v20{')'}, vil altså oversettes til det logisk uttrykket: {'('}delfilter.v1 OR delfilter1.v2{')'}AND{'('}delfilter2.v10 OR delfilter2.v20{')'}.</Text>

    <H2 className='boldOverride mt-40' id="dok-kodeeksempel">Bruk av APIet med Python, R eller Javascript</H2>
      <Text>Nedenfor følger noen korte kodesnutter som viser hvordan du kan kan benytte URLer fra API-konsollen eller URLer med egenkomponerte spørrestrenger til å laste ned data fra API-et. Eksempler vises for språkene Python, R og JavaScript.</Text>

      <H3 className='boldOverride mt-20'>Python</H3>
      <Text>Eksempel nedenfor benytter Python Pandas- og request-bibliotekene.</Text>
      <SyntaxHighlight label="Kodeeksempel" language="language-python" code={`import requests
import pandas as pd

# Limer inn URL fra API-konsollen
url = "https://api.statistikkbanken.udir.no/api/rest/v2/Eksport/152/data?filter=EierformID(-10)_EnhetID(-538_-12)_KjoennID(-10)_TidID(202112)_TrinnID(6_9)&format=0&sideNummer=1"

# GET-kall med requests-biblioteket
response = requests.get(url)

# Overfører dataene til en Pandas Dataframe og viser dem i skjermbilde
df = pd.read_json(response.text)
df`}/>

    <H3 className='boldOverride mt-20'>R</H3>
    <Text>Eksempel nedenfor benytter httr- og jsonlight-bibliotekenebliotekene.</Text>
    <SyntaxHighlight label="Kodeeksempel" language="language-r" 
      code={`# Du kan installere httr biblioteket med: install.packages("httr")
library(httr)
library(jsonlite)

# Limer inn URL fra API-konsollen
url = "https://api.statistikkbanken.udir.no/api/rest/v2/Eksport/152/data?filter=EierformID(-10)_EnhetID(-538_-12)_KjoennID(-10)_TidID(202112)_TrinnID(6_9)&format=0&sideNummer=1"

# GET-kall med httr-biblioteket
response <- GET(url)

# Overfører dataene til en R-type data.frame og viser dem i skjermbilde
df <- fromJSON(content(response, "text"))
df`}/>

    <H3 className='boldOverride mt-20'>Javascript</H3>
    <Text>Eksempel nedenfor benytter Javascript-kjøretidsmiljø med Node.js.</Text>
    <SyntaxHighlight label="Kodeeksempel" language="language-javascript" code={`import fetch from "node-fetch"

// Limer inn URL fra API-konsollen
const url = "https://api.statistikkbanken.udir.no/api/rest/v2/Eksport/152/data?filter=EierformID(-10)_EnhetID(-538_-12)_KjoennID(-10)_TidID(202112)_TrinnID(6_9)&format=0&sideNummer=1"

/**
 * Asynkron funksjon som gjør et GET-kall med bruk av fetch biblioteket
 * @param {string} url - URLen fra API-konsollen
 */
async function getUdirData(url) {
  let response = await fetch(url)
  let data = await response.json()
  return data;
}

const data = getUdirData(url).then(data => console.log(data));`}/>
  </Container>
)
}

export default ApiDokumentasjon