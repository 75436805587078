import React from 'react'
import { Grid, GridItem, Label, Text } from '@utdanningsdirektoratet/lisa'
import SyntaxHighlight from '../syntaxHighlighter/SyntaxHighlight'
import LisaTooltip from '../listaTooltip/LisaTooltip';
import { Alert, AlertTitle } from '@mui/material';

function Utviklerdokumentasjon({ requesturl, statuscode, responseerror, responsebody, noStatus = false, noHighlight = false }) {
    return (
        <>
            <Label className='mt-8'>Curl</Label>
            <div className="mb-8"><SyntaxHighlight label="Curl" language="language-bash" code={`curl -X 'GET' \\ \n '${requesturl}' \\ \n -H 'accept: */*'`} /></div>
            <Label>URL-spørring</Label>
            <div className="mb-8"><SyntaxHighlight label="URL-spørring" language="language-javascript" code={requesturl} /></div>
            { !noStatus && <>
                <LisaTooltip className='mt-8' label={<Label>Status</Label>} content="Betydning av statuskoder: 200 = Suksess, 400 = Ugyldig forespørsel (inkludert feil bruk av parametere), og 500 = Serverfeil." noMargin></LisaTooltip>
                <Grid gap="none">
                    <GridItem columns={{xsmall: 12, small:1}}><Text className="mb-8">{statuscode}</Text></GridItem>
                    { (responseerror != null) && 
                        <GridItem className="ml-20" columns={{xsmall: 12, small:11}}>
                            <Alert className="mb-8" severity="error">
                                <AlertTitle>API-kallet feilet - Statuskode {responseerror.response.status} {responseerror.response.statusText}</AlertTitle> 
                            </Alert>
                        </GridItem>
                    }
                </Grid>
            </>}
            {!(!responsebody || responsebody === "{}" || responsebody === "null") && <> 
                <Label>Resultat</Label>
                { noHighlight ? 
                    <div className="mb-8">
                        <SyntaxHighlight label="Resultat" code={responsebody} noHighlight/>
                    </div>         
                :
                    <div className="mb-8">
                        <SyntaxHighlight label="Resultat" language="language-json" code={responsebody}/>
                    </div>
                }
            </>}
        </>
    );
}

export default Utviklerdokumentasjon