import React from 'react'
import { object, func, bool } from 'prop-types'
import Checkbox from '../../form/Checkbox'
import { useSelector } from 'react-redux'

const MultipleChoiceFilterValue = ({
	filterValue,
	showFilterCode,
	selection,
	filterChangeHandler,
	active
}) => {

	const onlyOneTidID = useSelector(state => state.filter.pageData.onlyOneTidID)
	
	const labelStyle = {
		color: active? '' : '#ccc'
	}

	return (
		<div className="nssReactApp text-overflow">
			<Checkbox 
				className="nssReactApp"
				style={labelStyle}
				checked={selection && selection.has(filterValue.get('id'))}
				value={filterValue.get('id')}
				onChange={filterChangeHandler}
				disabled={!active || (filterValue.get('id')==onlyOneTidID)}
				>
				{showFilterCode
					? `${filterValue.get('kode')} - ${filterValue.get('navn')}`
					: filterValue.get('navn')}
			</Checkbox>
		</div>
	)
}

MultipleChoiceFilterValue.propTypes = {
	filterValue: object.isRequired,
	filterChangeHandler: func,
	showFilterCode: bool,
	selection: object,
	active: bool
}

export default MultipleChoiceFilterValue