import { connect } from 'react-redux'
import { removeActiveTextKey, getTextById } from '../ducks/text'
import Tooltip from './Tooltip'

const mapStateToProps = (state, ownProps) => ({
	text: state.text.getIn(['data', ownProps.textId]),
	textIsFetching: state.text.get('isFetching'),
	tooltipIsActive: state.text.get('activeTextKey') === ownProps.textKey
})

const mapDispatchToProps = dispatch => ({
	removeActiveTextKey: () => dispatch(removeActiveTextKey()),
	getTextById: (textId, textKey) => dispatch(getTextById(textId, textKey))
})

export default connect(mapStateToProps, mapDispatchToProps)(Tooltip)
