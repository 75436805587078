import React, { Fragment } from 'react'
import { object, string, bool, any, func, node } from 'prop-types'

Radiobutton.propTypes = {
	name: string,
	style: object,
	checked: bool,
	value: any,
	onChange: func,
	disabled: bool,
	children: node,
	notClickable: bool
}

export default function Radiobutton({
	style,
	value,
	checked,
	name,
	onChange,
	children,
	disabled,
	notClickable = false
}) {
	return (
		<div className="radio">
			<label style={style}>
				{!notClickable && (
					<Fragment>
						<input
							type="radio"
							className="sr-only"
							value={value}
							checked={!!checked}
							name={name}
							onChange={onChange}
							disabled={disabled}
						/>
						<span className="cr">
							<i className="cr-icon fa fa-circle" />
						</span>
					</Fragment>
				)}

				<span className="filter-label-name">{children}</span>
			</label>
		</div>
	)
}
