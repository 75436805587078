import { createSlice } from "@reduxjs/toolkit";

import urlBuilder from "../../util/urlBuilder";

const initialState = {
  //Tab
  selectedTab: 0,

  //URL
  selectedTable: null,
  selectedFilterId: null,
  selectedFilter: null,
  selectedFormat: 0,
  selectedPage: 1,

  numberOfPages: 1,

  //Response
  baseResponse: null,
  formatResponse: null, 
  filterSpecResponse: {}, 
  filterVerdierResponse: null,
  dataResponse: null, //Inkluderer filter
  sideDataResponse: null, //Inkluderer filter 

  //Loading
  loadingBaseResponse: false,
  loadingFormatResponse: false,
  loadingFilterSpecResponse: false,
  loadingFilterVerdierResonse: false, 
  loadingDataResponse: false,
  loadingSideDataResponse: false,

  showFilterResult: false,

  //Defaultuttrykk
  defaultUttrykk: {},

  //Paginering
  wrongPaginationInput: null,
  onlyOneTidID: null,
  
  //Datasett
  chosenDatasett: null, 
  chosenDatasettFilters: [], 
};

const pageData = createSlice({
  name: "pageData",
  initialState,
  reducers: {
    //Tab
    setSelectedTab(state, action) {
      state.selectedTab = action.payload
    },

    //URL deler 
    setSelectedTable(state, action) {
      state.selectedTable = action.payload
    },

    setSelectedFilterId(state, action) {
      state.selectedFilterId = action.payload;
    },
     
    setSelectedFilter(state, action) {
      if (action.payload !== null) {
        const [[key, tidIDListe]] = action.payload;
    
        if (key === "TidID") {
          state.onlyOneTidID = tidIDListe.size === 1 ? tidIDListe.values().next().value : null;
        }
    
        state.selectedFilter = urlBuilder(action.payload, key);
      } else {
        state.selectedFilter = action.payload;
      }
    },

    setSelectedFormat(state, action) {
      state.selectedFormat = action.payload
    },

    setSelectedPage(state, action) {
      state.selectedPage = action.payload
    },

    setNumberOfPages(state, action) {
      state.numberOfPages = action.payload
    },

    //Responser fra URL
    setBase(state, action) {
      state.baseResponse = action.payload
    },

    setFormat(state, action) {
      state.formatResponse = action.payload
    },

    setFilterVerdier(state, action) {
      state.filterVerdierResponse = action.payload
    },

    setData(state, action) {
      state.dataResponse = action.payload
    },

    setSideData(state, action) {
      state.sideDataResponse = action.payload
    },

    //Loading
    setLoadingBase(state, action) {
      state.loadingBaseResponse = action.payload
    },

    setLoadingFormat(state, action) {
      state.loadingFormatResponse = action.payload
    },

    setLoadingFilterSpec(state, action) {
      state.loadingFilterSpecResponse = action.payload
    },

    setLoadingFilterVerdier(state, action) {
      state.loadingFilterVerdierResonse= action.payload
    },

    setLoadingSideData(state, action) {
      state.loadingSideDataResponse = action.payload
    },

    resetDefaultUttrykk(state) {
      return {...state, defaultUttrykk: {}}
    },

    setFilterSpec(state, action) {
      state.filterSpecResponse = action.payload;
      Object.keys(action.payload.filterSpec).forEach((key) => {
        let obj = {};
        obj[key] = action.payload.filterSpec[key].defaultFilterUttrykk;
       if (obj[key]) {
          let cleanObj = JSON.parse(
            obj[key].replace(/([{,])(\s*)([A-Za-z0-9_\-]+?)\s*:/g, '$1"$3":'))
            state.defaultUttrykk = Object.assign(state.defaultUttrykk, cleanObj)
          
        }
      }); 
    },

    setSupportedFormats(state, action) {
      state.supportedFormats = action.payload;
    },

    returnFilterSpec(state, action) {
      return state.filterSpec;
    },

    setShowFilterResult(state, action) {
      state.showFilterResult = action.payload;
    },

    setWrongPaginationInput(state, action) {
      state.wrongPaginationInput = action.payload;
    },

    setChosenDatasett(state, action) {
      state.chosenDatasett = action.payload;
    },

    setChosenDatasettFilters(state, action) {
      state.chosenDatasettFilters = action.payload;
    }
  },
});

export const { setSelectedFilterId, setChosenDatasettFilters, setChosenDatasett, setWrongPaginationInput, setShowFilterResult, setSelectedTab, setFilterSpec, resetDefaultUttrykk, setSupportedFormats, setFilterVerdier, setLoadingBase, setLoadingFormat, setLoadingFilterSpec, setLoadingFilterVerdier, setLoadingSideData, setData, setNumberOfPages, setSideData, setSelectedPage, setBase, setSelectedTable, setSelectedFilter, setSelectedFormat, setFormat} =
pageData.actions;
export default pageData.reducer;