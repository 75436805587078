import { fromJS, Map } from "immutable";
import { baseURL } from "../../../../../../util/config";
import urlBuilderFilterStatus from "../../util/urlBuilderFilterStatus";
import axios from "axios";

export const types = {
  RECEIVE_ACTIVE_SELECTION: "RECEIVE_ACTIVE_SELECTION",
  REQUEST_FILTER_STATUS: "REQUEST_FILTER_STATUS",
  RECEIVE_FILTER_STATUS: "RECEIVE_FILTER_STATUS",
  RECEIVE_FILTER_STATUS_ERROR: "RECEIVE_FILTER_STATUS_ERROR",
};

const initialState = Map({
  loading: true,
	error: null,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RECEIVE_ACTIVE_SELECTION:
      return state.activeSelection;
    case types.RECEIVE_FILTER_STATUS:
      return state.merge(fromJS(action.activeValues));
    case types.RECEIVE_FILTER_STATUS_ERROR:
      return state.merge({ isFetching: false, error: action.error });
    default:
      return state;
  }
};

export const receiveActiveSelection = () => (dispatch, getState) => {
  const { filter } = getState();
  const { activeSelection } = filter;

  return activeSelection;
};

export const requestFilterStatus = (filterId) => ({
  type: types.REQUEST_FILTER_STATUS,
  filterId,
});

export const receiveFilterStatus = (filterId, activeValues) => ({
  type: types.RECEIVE_FILTER_STATUS,
  filterId,
  activeValues,
});

export const receiveFilterStatusError = (error) => ({
	type: types.RECEIVE_FILTER_STATUS_ERROR,
  error
})

export const fetchFilterStatus =
  (filterId = "", skipSelfCheck = true) =>
  (dispatch, getState) => {
    const { filter } = getState();
    const { activeSelection, shownFilters, filterSpec } = filter;
    const selectedTable = getState().filter.pageData.selectedTable;

    if (shownFilters.isEmpty()) return false;
     
    shownFilters
      .filter((val) => val)
      .map((val, key) => { 
        const tempActiveSelection = activeSelection
        const endpoint = urlBuilderFilterStatus(
          tempActiveSelection,
          `${baseURL}${selectedTable}/filterStatus`,
          `filterId=${key}`,
        )
        // sjekker ikke seg selv, eller
        if (filterId === key && skipSelfCheck === true) return false;

        // måltall skal ikke sjekkes
        if (key && !filterSpec.getIn(["items", key, "sjekkFilterStatus"])) return false;

        dispatch(requestFilterStatus(key))

        return axios.get(endpoint) 
          .then(response => { 
            dispatch(receiveFilterStatus(key, response.data))
          })
          .catch(error => {
            dispatch(receiveFilterStatusError(error))
          })
        }

      );
  };
