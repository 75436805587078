import React, { Component } from 'react'
import { bool, func, string } from 'prop-types'
import debounce from '../../util/debounce'
import { Button } from '@utdanningsdirektoratet/lisa'

export default class FilterSearch extends Component {
	static propTypes = {
		searchHandler: func,
		placeholder: string,
		showX: bool,
	}

	constructor(props) {
		super(props)
		this.debouncedChangeHandler = debounce(this.changeHandler, 400)
	}

	componentDidMount() {
		if (this.searchInput) this.clearHandler()
	}

	setClearButtonStyle() {
		if (this.searchInput.value === '') {
			this.showX = false
		} else {
			this.showX = true
		}
	}

	clearHandler = () => {
		this.searchInput.value = ''
		this.setClearButtonStyle()
		return this.props.searchHandler('')
	}

	changeHandler = () => {
		const inputValue = this.searchInput.value
		this.setClearButtonStyle()
		return this.props.searchHandler(inputValue)
	}

	keyHandler = event => {
		// Do nothing when pressing the Enter key
		if (event.key === 'Enter') event.preventDefault()
		if (this.searchInput.value !== '' && event.key === 'Escape') {
			this.clearHandler()
		}
	}

	render() {
		const { placeholder } = this.props

		return (
			<div className="filter-search">
				<input
					className="search-box"
					type="text"
					onChange={this.debouncedChangeHandler}
					onKeyDown={this.keyHandler}
					placeholder={placeholder}
					ref={elem => (this.searchInput = elem)}
				/>
				{this.showX && <Button aria={{ "aria-label": "Kryss" }} type="button" className="cross-button" onClick={this.clearHandler} icon="exit"></Button> }				
			</div>
		)
	}
}
