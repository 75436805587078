import { combineReducers } from 'redux'
import filter from './views/ApiKonsoll/FilterOptions/ducks/filter'
import text from './views/ApiKonsoll/FilterOptions/ducks/text' 


export default combineReducers(
	Object.assign(
		{},
		{ filter },
		{ text },
	)
)
