export default function searchForString(searchString, filterValues, hierarchy, searchInCode) {
	if (!searchString || searchString.length === 1) return false

	const filterElements = {}
	const lowerCaseSearch = searchString.toLowerCase()

	// 1. Finn match på rent søk
	filterValues.forEach(val => {
		const shouldSearchInCode = hierarchy
			? filterValues.get(Math.max(val.get('nivaa') - 1, 0))
			: searchInCode

		const stringToSearchIn = shouldSearchInCode
			? `${val.get('kode')} ${val.get('navn')}`
			: val.get('navn')

		// Uses indexOf since it is faster than includes
		if (stringToSearchIn && stringToSearchIn.toLowerCase().indexOf(lowerCaseSearch) > -1) {
			filterElements[val.get('indeks')] = 1

			if (hierarchy) {
				let parentIndex = val.get('forelder')
				while (parentIndex !== undefined && parentIndex > -1) {
					const parentNode = filterValues.get(parentIndex)
					filterElements[parentNode.get('indeks')] = 1

					parentIndex = parentNode.get('forelder')
				}
			}
		}
	})

	return Object.getOwnPropertyNames(filterElements).map(val => parseInt(val))
}
