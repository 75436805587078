//Lokalt mot SYSTST
//export const baseURL = 'https://systst-api.statistikkbanken.udir.no/api/rest/v2/Eksport/'
//export const datasett = 'https://systst-api.statistikkbanken.udir.no/function/api/GetFileList'

//Lokalt mot QA
//export const baseURL = 'https://qa-api.statistikkbanken.udir.no/api/rest/v2/Eksport/'
//export const datasett = 'https://qa-api.statistikkbanken.udir.no/function/api/GetFileList'

//Prod
export const baseURL = `${window._env_.REACT_APP_APIM_ENDPOINT}/api/rest/v2/Eksport/`
export const datasett = `${window._env_.REACT_APP_APIM_ENDPOINT}/function/api/GetFileList`
