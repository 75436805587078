// @ts-nocheck
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import rootReducer from './rootReducer'


const allMiddleware = [thunkMiddleware]

// Add redux logger if not in production
if (process.env.NODE_ENV !== `production`) {
	const createLogger = require(`redux-logger`).createLogger
	const logger = createLogger({ collapsed: true })
	allMiddleware.push(logger)
}

const Store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...allMiddleware)))

export default Store
