import React, { useEffect, useState } from "react";
import "./tableStats.css";
import { useDispatch, useSelector, useStore } from "react-redux";
import { setSelectedFormat, setSelectedTable } from "../FilterOptions/ducks/filter/pageData";
import { Card, H3, H2, Text, Loading, Autocomplete, Grid, GridItem, Accordion } from '@utdanningsdirektoratet/lisa';
import LisaTooltip from "../../../../components/listaTooltip/LisaTooltip";
import { useUrl } from '../../../hooks/useUrl';
import { resetState } from "../FilterOptions/ducks/filter/activeSelection";
import { resetDefaultUttrykk } from "../FilterOptions/ducks/filter/pageData";
import { resetShownFilters } from '../FilterOptions/ducks/filter/shownFilters'
import GetIcon from "../../../../components/getIcon/GetIcon";
import Utviklerdokumentasjon from "../../../../components/utviklerdokumentasjon/Utviklerdokumentasjon";

function TablePicker() {
  const dispatch = useDispatch();
  const baseUrl = useUrl("")
  const urlFormat = useUrl("format")
  const store = useStore()
  const baseResponse = useSelector(state => state.filter.pageData.baseResponse)
  const formatResponse = useSelector(state => state.filter.pageData.formatResponse)
  const loadingBase = useSelector(state => state.filter.pageData.loadingBaseResponse)
  const loadingFormat = useSelector(state => state.filter.pageData.loadingFormatResponse)
  const selectedTable = useSelector(state => state.filter.pageData.selectedTable)
  const [searchValue, setSearchValue] = useState(null);
  const [autocompleteItems, setAutocompleteItems] = useState([]);
  
  const itemInListClicked = (item) => {
    setSearchValue(item);
    dispatch(setSelectedTable(item.EksportID));
  };

  //Sjekker og fikser format på Oppdatert og Publisert
  const formatIsDate = (input) => {
    var date = new Date(input);
    return [
        ("0" + date.getDate()).slice(-2),
        ("0" + (date.getMonth() + 1)).slice(-2),
        date.getFullYear()
    ].join('/');
  }

  //Nullstiller filtervalg om ny tabell velges (ikke om man velger samme igjen)
  useEffect(() => {
    //Tømmer valgte verdier når man går fra en tabell, til en annen.

    //Litt hacky løsning fordi Accordion ikke lar oss styre om den er åpen eller lukket. 
    var detailsElements = document.getElementsByTagName("details");
    for (var i = 0; i < detailsElements.length; i++) {
      detailsElements[i].open = false;
    }

    if(store.getState().filter.activeSelection.size > 0) {
     dispatch(resetState())
     dispatch(resetDefaultUttrykk())
     dispatch(resetShownFilters())
   }
   
   dispatch(setSelectedFormat(0)); 
 }, [selectedTable]);

 //Henter ut tabeller og lager kategorier fra baseResponse
  useEffect(() => {
    let items = [];
    for(let i = 0; i < baseResponse?.length; i++) {
      const responseItem = baseResponse[i];
      const category = items.find(i => i.categoryHeader === responseItem.Tabellnavn);
      if (category) {
        category.items.push(responseItem);
      } else {
        items.push({
          items: [ responseItem ],
          categoryHeader: responseItem.Tabellnavn
        });
      }
    }
    setAutocompleteItems(items);
  }, [baseResponse]);

  return (
    <Card backgroundColor="hvit" underlineColor="stålblå400">
      <Grid>
        <GridItem columns={{ small: 12, medium: 6, large: 6 }}>
          <LisaTooltip label={<H2 textStyle="subtitle-large" className='mb-0 boldOverride'>Søk etter tabell</H2>} content="Velg tabell ved å skrive tabellnavn eller tabellnummer, eller bla gjennom nedtrekkslisten."></LisaTooltip>
            {loadingBase ? <Loading className="mt-16" pending={loadingBase} size="large" /> :
            <Autocomplete
              className="mb-20"
              items={autocompleteItems}
              disabled={!baseResponse ? true : false}
              icon="search"
              placeholder="Søk via tabellnavn eller tabellnummer"
              searchFunction={(i) => `${i.Beskrivelse} - ${i.EksportID}`}
              itemLabel={baseResponse ? (i) => `${i.EksportID} - ${i.Beskrivelse}` : (i) => `${i.categoryHeader}`}
              onItemClicked={itemInListClicked}
              width="auto"
            />
            }
          {(baseResponse != null && searchValue) && <>
            <Grid gap="none">
              <GridItem className="mb-8 mr-20" columns={{ }}>
                <H3 textStyle="bodyBold" className='mb-0'>Oppdatert</H3>
                <Text>{searchValue.OppdatertDatoTid ? formatIsDate(searchValue.OppdatertDatoTid) : searchValue.OppdatertDatoTid}</Text>
              </GridItem>
              <GridItem className="mb-8 mr-8" columns={{ xsmall: 12, small: 2 }}>
                <H3 textStyle="bodyBold" className='mb-0'>Publisert</H3>
                <Text>{searchValue.PubliseresDatoTid ? formatIsDate(searchValue.PubliseresDatoTid) : searchValue.PubliseresDatoTid}</Text>
              </GridItem>
            </Grid>
          </>}
        </GridItem>
        <GridItem columns={{ small: 12, medium: 6 }}>
          <LisaTooltip label={<H2 textStyle="subtitle-large" className='mb-0 boldOverride text-overflow'>Utviklerdokumentasjon</H2>} content="Utviklerdokumentasjonen gir mer informasjon om hvordan API-et er satt opp, og hvordan man kan hente ut ønsket datautvalg."></LisaTooltip>
          <Accordion className="border-bottom p-4" title={<GetIcon label={<H3 textStyle="bodyBold" className='m-0'>Tilgjengelige tabeller</H3>}/>}>
            { loadingBase ? <Loading className="mt-20 mb-20" pending={loadingBase} size="large"/>:
              <Utviklerdokumentasjon
                requesturl={baseUrl}
                responsebody={JSON.stringify(baseResponse, null, " ")}
                noStatus
                noHighlight/>
            }
          </Accordion>
          <Accordion className="border-bottom p-4" title={<GetIcon label={<H3 textStyle="bodyBold" className='m-0'>Tilgjengelige formater</H3>}/>}>
            { loadingFormat ? <Loading className="mt-20 mb-20" pending={loadingFormat} size="large"/>:
              <Utviklerdokumentasjon
                requesturl={urlFormat}
                responsebody={JSON.stringify(formatResponse, null, " ")}
                noStatus
              ></Utviklerdokumentasjon>
            }
          </Accordion>
        </GridItem>
      </Grid>
    </Card>
  );
}

export default TablePicker;
