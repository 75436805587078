import React, { useEffect, useState } from 'react'
import { Container, Text, Button, Icon, FlexRow, InputField } from '@utdanningsdirektoratet/lisa'
import { setSelectedPage, setWrongPaginationInput } from '../../app/views/ApiKonsoll/FilterOptions/ducks/filter/pageData';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertTitle, Collapse } from '@mui/material';
import "./paginering.css"

function Paginering() {
    const dispatch = useDispatch()
    const numberOfPages = useSelector(state => state.filter.pageData.numberOfPages)
    const wrongInput = useSelector(state => state.filter.pageData.wrongPaginationInput)
    const [page, setPage] = useState(1);
    const [highest, setHighest] = useState(false);
    const [lowest, setLowest] = useState(false);

    const lowerPage = () => {
        let count = Number(page)
        setPage(count - 1);
        check(count - 1)
    };

    const upPage = () => {
        let count = Number(page)
        setPage(count + 1);
        check(count + 1)
    };

    const check = (input) => {
        if (!numberOfPages) {
            setPage(0)
            dispatch(setSelectedPage(0))
            dispatch(setWrongPaginationInput(null))
            setLowest(true)
            setHighest(true)
            return
        }

        if (input === "") {
            setPage("")
            dispatch(setWrongPaginationInput(null))
            setLowest(true)
            setHighest(true)
            return
        }

        if ( (!Number.isInteger(Number(input))) || Number(input) < 1 || Number(input) > numberOfPages) {
            setPage(input)
            dispatch(setWrongPaginationInput(input))
            return
        }

        if (Number(input) == numberOfPages) {
            setHighest(true)
        }
        else {
            setHighest(false)
        }

        if (Number(input) == 1) {
            setLowest(true)
        }
        else {
            setLowest(false)
        }
        setPage(input)
        dispatch(setWrongPaginationInput(null))
        dispatch(setSelectedPage(input))
    }

    const inputSize = (() => {
        if (numberOfPages < 10) {
            return 'input-small'
        }
        else if (numberOfPages < 100) {
            return 'input-medium'
        }
        else if (numberOfPages < 1000) {
            return 'input-large'
        }
        else if (numberOfPages < 10000) {
            return 'input-xlarge'
        }
        return 'input-xxlarge'
    })()

    useEffect(() => {
        check(page)
    }, [numberOfPages])

    return (
        <div>
            <Container className='paginering'>
                <FlexRow marginBetween={4}>  
                    <Button className="pl-8 pr-8" aria={{ "aria-label": "Pil venstre" }} disabled={lowest} onClick={lowerPage}><Icon direction="left" type="chevron"/></Button>
                    
                    <InputField className={`input-styling ${inputSize}`} handleChange={(evt) => check(evt.target.value)} value={page} type="number" id="pagineringInput" label="input" hideLabel="true"></InputField>
                
                    <Text>av {(!numberOfPages) ? "0" : numberOfPages}</Text>
                    <Button className="pl-8 pr-8" aria={{ "aria-label": "Pil høyre" }} disabled={highest} onClick={upPage}><Icon direction="right" type="chevron"/></Button>
                </FlexRow>
            </Container>
            <Collapse in={wrongInput}><Alert className="mt-8" severity="warning"><AlertTitle>Input "{wrongInput}" er ugyldig</AlertTitle>Sidetall må være mellom 1 og {numberOfPages}.</Alert></Collapse>
        </div>
    );
}

export default Paginering