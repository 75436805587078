import { Map } from 'immutable'

export const types = {
	TOGGLE_SHOW_FILTER: 'TOGGLE_SHOW_FILTER',
	RESET_SHOWN_FILTERS: 'RESET_SHOWN_FILTERS'
}

export default (state = Map(), action) => {
	switch (action.type) {
		case types.TOGGLE_SHOW_FILTER:
			return state.set(action.filterId, !state.get(action.filterId, false))
		case types.RESET_SHOWN_FILTERS:
			return Map()
		default:
			return state
	}
}

export const toggleShowFilter = filterId => ({
	type: types.TOGGLE_SHOW_FILTER,
	filterId
})

export const resetShownFilters = () => ({
	type: types.RESET_SHOWN_FILTERS
})
