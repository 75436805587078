import React from 'react'
import { H1, H2, H3, Link, Text, Container, Label, FlexColumn, FlexRow} from '@utdanningsdirektoratet/lisa'
import imageJusterFilter from './assets/JusterFilter.png'
import imageTestFiltervalg from './assets/TestFiltervalg.png'
import imageVelgTabell from './assets/VelgTabell.png'
import { setSelectedTab } from "./../ApiKonsoll/FilterOptions/ducks/filter/pageData";
import "./brukerveiledning.css"
import { useDispatch } from 'react-redux'

function Brukerveiledning() {  
    const dispatch = useDispatch();
    const margin = {
        x : "auto"
    }

    const padding = {
        right: 40,
        top: 20,
        bottom: 20, 
        left: 40,
    }

    const handleClickScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView();
        }
    };

    return (
        <Container margin={margin} maxWidth="900px">
            <H1>Brukerveiledning</H1>
                <Text className="mb-40" textStyle='subtitle'>På denne siden finner du en stegvis gjennomgang av hvordan du kan bruke API-konsollen. Informasjon om oppbygning av data og selve API-et finner du under fanen <Link onClick={() => dispatch(setSelectedTab(3))}>Dokumentasjon</Link>.</Text>
            <H2 className='boldOverride mt-40'>Å bruke API-konsollen på 1, 2, 3</H2>
                <Text className="mb-20">API-konsollen lar deg filtrere og hente ut et utvalg data (omtalt her som å kjøre en spørring mot API-et). Den er ment for å teste og å lære om dataene, samt om selve API-et.</Text>
                <Text className="mb-20">Per i dag henter API-konsollen kun ut data fra Elevundersøkelsen. Det er åtte mulige tabeller du kan kjøre API-spørringer mot: Indikator, Tema, Mobbing og Deltakelse-tabellene for grunnskolen, og tilsvarende tabeller for videregående. Hvilken tabell du bør velge, avhenger av hvilke data du ønsker å laste ned fra undersøkelsen. Du finner mer informasjon om Elevundersøkelsen på Udir sine nettsider <Link url={"https://www.udir.no/tall-og-forskning/statistikk/elevundersokelsen/"}>(Resultater fra elevundersøkelsen)</Link>.</Text>
            
            <H3 className="boldOverride">Innholdsfortegnelse</H3>
                <ul className='mb-40'>
                <li className='mb-4'><Link onClick={() => handleClickScroll("bruker-velg-tabell")}>Velg tabell</Link></li>
                <li className='mb-4'><Link onClick={() => handleClickScroll("bruker-juster")}>Tilpass datautvalg</Link></li>
                <li className='mb-4'><Link onClick={() => handleClickScroll("bruker-test")}>Test datautvalg</Link></li>
                </ul>

            <H3 className='boldOverride mt-40' id="bruker-velg-tabell">1. Velg tabell</H3>
                <Text className='mb-20'>Første steg i API-konsollen er å velge en tabell.
                </Text>
                <img className="image-size" src={imageVelgTabell} alt="Utsnitt av API-konsollen tilført oransje rundinger med ulike bokstaver, plassert ved innholdet: a. Velg en tabell, b. Informasjon, c.Utviklerdokumentasjon. Forklaring av innholdet gjennomgås i tilsvarende bokstavrekkefølge under bildet." />
                <ul className="letter-list mb-20">
                    <li style={{listStyleType: "'a.  '"}}><b>Velg en tabell</b> ved å trykke på søkefeltet og bla gjennom tabellene i nedtrekkslisten. Du kan også skrive tabellnavn eller tabellnummer direkte inn i søkefeltet.</li>
                    <li style={{listStyleType: "'b.  '"}}><b>Informasjon</b> om publiseringstidspunkt og siste tidspunkt for oppdatering vises etter at du har valgt en tabell.</li>
                    <li style={{listStyleType: "'c.  '"}}><b>Utviklerdokumentasjon</b> beskriver to API-metoder som henholdsvis returnerer en oversikt over tilgjengelige tabeller og tilgjengelige formater.</li>
                </ul>

            <H3 className='boldOverride mt-40' id="bruker-juster">2. Tilpass datautvalg</H3>
                <Text className='mb-20'>Andre steg i API-konsollen er å velge format og justere filtre. URL-en til tabellen du valgte vises øverst under tittelen 'URL-spørring'. Ved å kopiere URL-en og lime den inn i nettleseren din, får du lastet ned data fra tabellen. Endringer i filterverdier og format oppdateres automatisk og vises i URL-en. Du kan selv tilpasse en kopiert URL direkte i nettleseren.</Text>
                <img className="image-size" src={imageJusterFilter} alt="Utsnitt av API-konsollen tilført oransje rundinger med ulike bokstaver, plassert ved innholdet: d. Hent ut dataen, e. Velg format, f. Velg side, g. Velg format, h. Tilbakestill filter, i. Utviklerdokumentasjon. Forklaring av innholdet gjennomgås i tilsvarende bokstavrekkefølge under bildet." />
                <ul className="mb-20 letter-list">

                    <li style={{listStyleType: "'d.  '"}}><b>Hent ut dataene</b> fra tabellen ved å trykke på kopier-ikonet og lim URL-en inn i nettleseren din. Filterverdier, valg av format og side er gjenspeilet i URL-en. </li>
                    <li style={{listStyleType: "'e.  '"}}><b>Velg format</b> ved å trykke på nedtrekkskomponenten med tittel 'Velg format'. JSON er valgt som standard verdi.</li>
                    <li style={{listStyleType: "'f.  '"}}><b>Velg side</b> ved å benytte piltastene eller ved å skrive inn ønsket sidenummer i komponenten med tittel 'Velg side'.</li>
                    <li style={{listStyleType: "'g.  '"}}><b>Velg filter</b> for å avgrense hvilke data som blir hentet ut fra API-et. Filtrene varierer fra tabell til tabell, og hver tabell kommer med forhåndsvalgte filterverdier. Trykk på et filter for å åpne det, og bruk avmarkeringsboksene til å justere filtreringen.</li>
                    <li style={{listStyleType: "'h.  '"}}><b>Tilbakestill filtre</b> til forhåndsvalgte filterverdier ved å benytte knappen 'Tilbakestill filter'</li>
                    <li style={{listStyleType: "'i.  '"}}><b>Utviklerdokumentasjon</b> beskriver fire API-metoder hvor de første to returnerer en oversikt over henholdsvis tilgjengelige filtre og filterverdier for valgt tabell. Den tredje returnerer gyldige filtervalg for ett bestemt filter i valgt tabell. Velg bestemt filter (filterId) ved hjelp av nedtrekksmenyen. Den siste returnerer størrelsen på responsen av den aktuelle API-spørringen.</li>
                </ul>
                <Container className="mb-20 shadow" borderRadius="small" backgroundColor='#CCE6D8' padding={padding}>
                    <FlexRow valign="start" marginBetween={8}>
                        <FlexColumn><Label textStyle="bodyBold">Merk:</Label></FlexColumn>
                        <FlexColumn><Text>Dersom tabellen er over en gitt størrelse vil den splittes opp i sider, og URL-en vil kun hente ut én side av gangen. For å få ut det komplette datasettet finnes det ulike løsninger. Dersom du er interessert i komplette datasett uten filtre kan du i fanen 'Hele datasett' laste ned dataene direkte. Er du interessert i å beholde de valgte filtrene kan du innad i API-konsollen bytte sidenummer flere ganger via 'Velg side'-komponenten (for så å kopiere den nye URL-en for hver gang), eller så kan du utenom API-konsollen selv endre verdien 'sideNummer' direkte i URL-en.</Text></FlexColumn>
                    </FlexRow>
                </Container>

            <H3 className='boldOverride mt-40' id="bruker-test">3. Test datautvalg</H3>
                <Text className='mb-20'>Tredje steg i API-konsollen er å teste tabellen med filtervalgene dine. I praksis blir det gjort en spørring til API-et, og du får se responsen. Dette gir deg mulighet til å se igjennom dataene du vil få ut via spørringen du har laget, og sjekke om du har valgt ønskede filtre og format.</Text>
                <img className="image-size" src={imageTestFiltervalg} alt="Utsnitt av API-konsollen tilført oransje rundinger med ulike bokstaver, plassert ved innholdet: j. Test datautvalg, k. Response, l. Hent ut dataen. Forklaring av innholdet gjennomgås i tilsvarende bokstavrekkefølge under bildet." />
                <ul className="letter-list">
                    <li style={{listStyleType: "'j.  '"}}>'<b>Test datautvalg</b>'-knappen kjører API-metoden 'Hent ut tilpasset datautvalg' som returerer et resultat basert på valg av tabell, filtre og format.</li>
                    <li style={{listStyleType: "'k.  '"}}><b>Responsen</b> på API-kallet er delt inn i bolkene Curl (Client URL), URL-spørring, Status og Resultat. Beskrivelse av de ulike statuskodene finner du i informasjonsikonet tilhørende 'Status'-tittelen. Du kan også lese mer om statuskodene under fanen <Link onClick={() => dispatch(setSelectedTab(3))}>Dokumentasjon</Link>.</li>
                    <li style={{listStyleType: "'l.  '"}}><b>Hent ut dataene</b> fra tabellen ved å benytte knappen med kopier-ikon innad i hver av kodeboksene.</li>
                </ul>
        </Container>
  )
}

export default Brukerveiledning