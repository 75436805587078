import React from 'react'
import { FlexColumn, FlexRow, Icon, TextWithTooltip } from '@utdanningsdirektoratet/lisa'

function LisaTooltip({ className, label, content, noMargin = false }) {
    return (
      <FlexRow marginBetween={4} className={ noMargin ? `${className}` : `mb-8 ${className}`}>
        <FlexColumn>{label}</FlexColumn>
        <FlexColumn aria-label="Infosirkel"><TextWithTooltip className="mt-4" tooltipContent={content}> <Icon type="circledInfo"/> </TextWithTooltip></FlexColumn>
      </FlexRow>
    );
}

export default LisaTooltip