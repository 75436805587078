import React, { Component } from 'react'
import { bool, func, object, number, string, array } from 'prop-types'
import Radiobutton from '../../form/RadioButton'

export default class SingleChoiceTreeFilterValue extends Component {
	static propTypes = {
		activeSearch: string,
		selected: bool,
		setActiveTabState: func.isRequired,
		selection: object,
		index: number,
		filterValues: object,
		filterStatus: object,
		showFilterCode: bool,
		filter: object,
		matchingFilters: array,
		hasActiveTabState: func,
		setFilterSelection: func,
	}

	constructor(props) {
		super(props)
		this.state = { expanded: props.selected ? props.selected : this.shouldExpandOnInit() } // Sjekk seg selv først, sjekk om barn er valgt
	}

	shouldExpandOnInit() {
		const { selection, index, filterValues } = this.props
		if (!selection) return false

		// Finn hvilke filterVerdi som er valgt
		const currentChosenFilter = filterValues.find(
			(filter) => filter.get('id') === selection.first()
		)
		if (currentChosenFilter !== undefined) {
			let chosenFilterParentIndex = currentChosenFilter.get('forelder')

			while (chosenFilterParentIndex !== undefined && chosenFilterParentIndex > -1) {
				// Sjekk om valgt filterverdi sin forelder er "this"
				if (chosenFilterParentIndex === index) return true

				chosenFilterParentIndex = filterValues.getIn([chosenFilterParentIndex, 'forelder'])
			}
		}
		return false
	}

	toggleTree(event, id) {
		this.props.setActiveTabState(id)
		this.setState(
			Object.assign({}, this.state, {
				expanded: !this.state.expanded,
			})
		)
	}

	searchIsActive = () => {
		const { activeSearch } = this.props
		return activeSearch && activeSearch.length > 0
	}

	renderInput() {
		const {
			index,
			showFilterCode,
			filter,
			filterValues,
			setFilterSelection,
			setActiveTabState,
			filterStatus,
			selected,
		} = this.props

		const filterValue = filterValues.get(index)

		const active = filterStatus && filterStatus.includes(filterValue.get('id'))
		const checkedButton = selected || false

		const labelStyle = {
			color: active ? '' : '#ccc',
		}

		const clickHandler = (event) => {
			setFilterSelection(filter.get('id'), event.target.value)
			setActiveTabState(null)
		}

		return (
			<Radiobutton
				style={labelStyle}
				checked={checkedButton}
				value={filterValue.get('id')}
				onChange={clickHandler}
				disabled={!active}
				notClickable={filterValue.get('ikkeVelgbart', false)}
			>
				{showFilterCode
					? `${filterValue.get('kode')} - ${filterValue.get('navn')}`
					: filterValue.get('navn')}
			</Radiobutton>
		)
	}

	render() {
		const {
			index,
			filter,
			filterValues,
			filterStatus,
			matchingFilters,
			activeSearch,
			selection,
			hasActiveTabState,
			setActiveTabState,
			setFilterSelection,
		} = this.props

		if (activeSearch && !matchingFilters.includes(index)) return false

		const filterValue = filterValues.get(index)
		const childNodes = filterValue.get('barn')

		if (filterValue.get('barn')) {
			const iconClass =
				this.state.expanded || activeSearch ? 'fa fa-minus-square' : 'fa fa-plus-square'
			const buttonText =
				this.state.expanded || activeSearch
					? `Lukk ${filterValue.get('navn')}`
					: `Ekspander ${filterValue.get('navn')}`
			const ExpandButton = () => (
				<button
					className="tree-button"
					key={filterValue.get('id')}
					tabIndex="0"
					autoFocus={hasActiveTabState(filterValue.get('id'))}
					aria-label={buttonText}
					onClick={(e) => this.toggleTree(e, filterValue.get('id'))}
					onKeyPress={(e) => this.toggleTree(e, filterValue.get('id'))}
				>
					<i className={iconClass} />
				</button>
			)
			return (
				<li>
					{!this.searchIsActive() && <ExpandButton />}
					{this.renderInput()}
					{(this.state.expanded || activeSearch) && (
						<ul>
							{childNodes.map((value, idx) => (
								<SingleChoiceTreeFilterValue
									key={idx}
									index={value}
									filter={filter}
									filterValues={filterValues}
									filterStatus={filterStatus}
									matchingFilters={matchingFilters}
									showFilterCode={filter.getIn([
										'visKode',
										Math.max(filterValues.getIn([value, 'nivaa']) - 1, 0),
									])}
									setActiveTabState={setActiveTabState}
									hasActiveTabState={hasActiveTabState}
									activeSearch={activeSearch}
									selected={Boolean(selection && selection.get(filterValues.getIn([value, 'id'])))}
									selection={selection}
									setFilterSelection={setFilterSelection}
								/>
							))}
						</ul>
					)}
				</li>
			)
		}
		const levelClass = this.searchIsActive() ? '' : 'last-level'
		return <li className={levelClass}>{this.renderInput()}</li>
	}
}
