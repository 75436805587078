import { connect } from 'react-redux'
import { toggleFilterSticky } from '../../ducks/filter/filterSticky'
import { resetFilterSelection } from '../../ducks/filter/activeSelection'
import { toggleFilterPanel } from '../../ducks/filter/filterPanelMinimized'
import FilterPanel from './FilterPanel'
import { fetchFilterStatus } from '../../ducks/filter/filterStatus'

const mapStateToProps = state => ({
	minimized: state.filter.filterPanelMinimized,
	filterSpec: state.filter.filterSpec,
})

const mapDispatchToProps = dispatch => ({
	toggleFilterSticky: () => dispatch(toggleFilterSticky()),
	resetFilterSelection: () => dispatch(resetFilterSelection()),
	fetchFilter: () => dispatch(fetchFilterStatus()),
	toggleFilterPanel: () => dispatch(toggleFilterPanel())
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterPanel)
