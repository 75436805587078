import React, { useEffect, useState } from 'react'
import { datasett } from '../../../util/config'
import { H1, H2, Text, Container, Card, Loading, GridItem, Grid } from '@utdanningsdirektoratet/lisa'
import LisaSearchTable from '../../../components/lisaSearchTable/LisaSearchTable'
import VelgDatasett from '../../../components/velgDatasett/VelgDatasett'
import { useSelector } from 'react-redux'
import { Alert, AlertTitle } from '@mui/material'
import axios from 'axios'

function Datasett() {  
  const { chosenDatasett } = useSelector(state => state.filter.pageData)
  const [datasettResponse, setDatasettResponse] = useState(null)
  const [loadingDatasett, setLoadingDatasett] = useState(false)
  const [dataResponseError, setDataResponseError] = useState(false);

  const getDatasett = async () => {
    setLoadingDatasett(true)
  
    try {
      const response = await axios.get(datasett)
      setDatasettResponse(response.data)
      setDataResponseError(false)
    } catch (error) {
      setDataResponseError(true)
    }
    setLoadingDatasett(false)
  };
  
  useEffect(() => {
    getDatasett()
  }, []);

  return (
    <Container margin={{x: "auto"}} maxWidth="1400px">
      <H1>Last ned hele datasett</H1>
      <Text className="mb-20" textStyle='subtitle'>På denne siden finner du komplette datasett for tabellene tilgjengelige i API-konsollen. Ut-formatet er CSV. Last ned ønsket tabell fra listen under.</Text>
      {dataResponseError ? <Alert severity="error"><AlertTitle>Henting av hele datasett feilet</AlertTitle></Alert> : 
        <>
          {!datasettResponse ? <Text textColor="grå700">Ingen datasett tilgjengelig</Text> : 
            <Card backgroundColor="hvit" underlineColor="stålblå400">
                <Grid>
                  <GridItem className="mb-8" columns={{xsmall:12, small: 12, medium: 3}} flexGrow={1}>
                    { loadingDatasett || datasettResponse === null ? <Loading className="mt-16" pending={loadingDatasett} size="large" /> :
                      <Container borderRadius="medium" backgroundColor={"#e5ebf2"} className="p-12">
                        <H2 className="boldOverride" textStyle="subtitle-large">Velg tabell</H2>
                        <VelgDatasett content={datasettResponse}></VelgDatasett> 
                      </Container>
                    }
                  </GridItem>
                  <GridItem columns={{ xsmall:12, small: 12, medium: 9}} >
                  {(!chosenDatasett && !loadingDatasett) ? <Text textColor="grå700">Ingen tabell valgt</Text> : (
                    <LisaSearchTable
                      tableContent={chosenDatasett.RapportListe}
                      caption={
                        <H2 className="boldOverride mb-0 mt-8" textStyle="subtitle-large">
                          Hele datasett fra {chosenDatasett.Fakta}, {chosenDatasett.Register}
                        </H2>
                      }
                    />
                  )}
                  </GridItem>
                </Grid> 
            </Card>
          }
        </>
      }
    </Container>
  )
}

export default Datasett