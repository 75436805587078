import React from 'react'
import { Link, H1, Container, Grid, GridItem } from '@utdanningsdirektoratet/lisa'
import imageProgrammer from './Assets/programmer.png'
import "./banner.css"

function Banner({className}) {
    const padding = {
        right: 16,
        top: 16,
        bottom: 20, 
        left: 16,
    }

    return (
        <Container className={`shadow ${className}`} borderRadius="small" padding={padding} backgroundColor="lysAzur400">
            <Grid gap="none">
                <GridItem  className="text-overflow content mr-12" columns={{xsmall: "12", small:"11", medium:"10"}}>
                    <div>
                        <H1>Fremgangsmåte</H1>
                        <ol className="ol">
                            <li>Velg ønsket tabell</li>
                            <li>Juster filtre, format og sidetall etter ønske. Hent ut dataene fra tabellen ved å kopiere URL-spørringen og lime den inn i en nettleser</li>
                            <li>Ønsker du å undersøke hvordan dataene vil se ut kan du benytte deg av 'Test datautvalg'-knappen</li>
                            <li>Bruk utviklerdokumentasjonen dersom du ønsker mer informasjon om hvordan API-et er satt opp, og hvordan man kan hente ut ønsket datautvalg</li>
                        </ol>
                    </div>
                </GridItem>
                <GridItem className="image" columns={{xsmall: "12", small:"1",}}>
                    <img style={{ width: 110 }} src={imageProgrammer} alt="Person med datamaskin i fanget." />
                </GridItem>
            </Grid>
        </Container>
    );
}

export default Banner
