import React, { Component } from "react";
import { bool, func, object, string } from "prop-types";
import FilterTypeConnector from "../filter-type/FilterTypeConnector";
import { Button, Container } from "@utdanningsdirektoratet/lisa";

export default class FilterPanel extends Component {
  static propTypes = {
    minimized: bool,
    resetFilterSelection: func,
    toggleFilterPanel: func,
    filterSpec: object,
    fetchFilter: func,
    reportElementType: string,
    toggleFilterSticky: func,
  };

  render() {
    const {
      resetFilterSelection,
      filterSpec,
    } = this.props;
    
    return (
      <Container id="accordion-filter" borderRadius="medium" backgroundColor={"#e5ebf2"} className="p-12">
        <Button
          variant="outlined"
          onClick={resetFilterSelection}>
          Tilbakestill filter
        </Button>
        <div>
          {filterSpec.get("items") && 
            filterSpec
            .get("items")
            .valueSeq()
            .map((filter, filterIndex) => (
              <FilterTypeConnector key={filterIndex} filter={filter} />
            ))
          }
        </div>
      </Container>
    );
  }
}
