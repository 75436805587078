import React, { Component } from 'react'
import { object, func } from 'prop-types'
import MultipleChoiceFilterValue from './MultipleChoiceFilterValue'
import FilterSearch from '../filter-search/FilterSearch'
import FilterSelectAll from '../filter-select-all/FilterSelectAll'
import { connect } from 'react-redux';
import { Button } from '@utdanningsdirektoratet/lisa'

  class MultipleChoiceFilter extends Component {
	
	static propTypes = {
		filter: object.isRequired,
		filterValues: object.isRequired,
		searchForString: func.isRequired,
		toggleFilterSelection: func.isRequired,
		selection: object,
		filterStatus: object,
		toggleFilterSelectionAll: func
	}

	constructor(props) {
		super(props)
		this.state = {
			expanded: false,
			activeSearch: '',
			matchingFilters: {}
		}
	}

	searchHandler = searchtext => {
		if (searchtext === '') {
			this.setState({
				activeSearch: '',
				matchingFilters: []
			})

			return false
		}
		const { filterValues, filter } = this.props

		const matchingFilters = this.props.searchForString(
			searchtext,
			filterValues,
			false,
			filter.get('visKode')
		)
		if (matchingFilters) {
			this.setState(
				Object.assign({}, this.state, {
					activeSearch: searchtext.toLowerCase(),
					matchingFilters
				})
			)
		}
	}

	renderShowMoreButton(filter, filterValues, activeSearch, expanded) {
		if (filterValues.size <= 10 || activeSearch) return false

		const toggleShowAll = () => {
			this.setState(
				Object.assign({}, this.state, {
					expanded: !expanded
				})
			)
		}

		// Flertallsform
		const showAllLabel = filter.get('visningsNavnFlertall')
			? filter.getIn(['visningsNavnFlertall', 0])
			: filter.get('navn')

		return (	
			<Button variant="outlined" onClick={toggleShowAll}>
				{expanded
					? `Vis færre ${showAllLabel} (10)`
					: `Vis alle ${showAllLabel} (${filterValues.size})`}
			</Button>
		)
	}

	render() {
		const {
			filter,
			filterValues,
			toggleFilterSelection,
			selection,
			filterStatus,
			toggleFilterSelectionAll
		} = this.props
		const { activeSearch, expanded, matchingFilters } = this.state
		let filterElements
		
		if (activeSearch) {
			filterElements = filterValues.filter(val => matchingFilters.includes(val.get('indeks')))
		} else if (this.state.expanded) {
			filterElements = filterValues
		} else {
			filterElements = filterValues.slice(0, 10)
		}

		const filterElementsRendered = filterElements.map((value, idx) => (
			<MultipleChoiceFilterValue
				key={idx}
				selection={selection}
				filterValue={value}
				filterChangeHandler={event =>
					toggleFilterSelection(
						filter.get('id'),
						event.currentTarget.value,
						value.get('indeks'),
						filter.get('valgModus'),
					)
				}
				
				active={filterStatus && filterStatus.includes(value.get('id'))}
				showFilterCode={filter.get('visKode')}
			/>
		))

		const showMoreButton = this.renderShowMoreButton(filter, filterValues, activeSearch, expanded)

		return (
			<div>
				{filter.get('visSoekefelt') && (
					<div className="nssReactApp">
						<FilterSearch
							searchHandler={this.searchHandler}
							placeholder={`Søk etter ${filter.get('navn').toLowerCase()}`}
						/>
					</div>
				)}
				<div className="filter-values">
					{filter.get('visVelgAlle') && (
						<FilterSelectAll
							selection={selection}
							filterId={filter.get('id')}
							filterValues={activeSearch ? filterElements : filterValues}
							selectAllHandler={toggleFilterSelectionAll}
						/>
					)}
					{expanded && <div className="mb-12"> {showMoreButton} </div>}
					{filterElementsRendered}
					<div className='mt-12 mb-4'>{showMoreButton}</div>
					{activeSearch &&
						!filterElements.size && (
							<div>
								<i>Søket ditt ga ingen treff</i>
							</div>
						)}
				</div>
			</div>
		)
	}
}

export default connect(null)(MultipleChoiceFilter)