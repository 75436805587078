// @ts-nocheck
import React, { Component } from 'react'
import { object, func } from 'prop-types'
import MultipleChoiceTreeFilterValue from './MultipleChoiceTreeFilterValue'
import FilterSearch from '../filter-search/FilterSearch'
import TreeFilterSelectAll from '../filter-select-all/TreeFilterSelectAll'
import TreeFilterSelectAllSubLevel from '../filter-select-all/TreeFilterSelectAllSubLevel'

const MAX_SEARCH_RESULTS = 100

export default class MultipleChoiceTreeFilter extends Component {
	static propTypes = {
		filter: object.isRequired,
		filterValues: object.isRequired,
		searchForString: func.isRequired,
		filterStatus: object,
		selection: object,
		toggleFilterSelection: func.isRequired,
		toggleFilterSelectionAll: func.isRequired,
		toggleFilterSelectionAllSubLevel: func.isRequired,
	}

	constructor(props) {
		super(props)
		this.state = {
			activeSearch: '',
			matchingFilters: [],
			activeTabStateId: null,
		}
	}

	setActiveTabState = (id) => {
		this.setState({ activeTabStateId: id })
	}

	searchHandler = (searchtext) => {
		if (searchtext === '') {
			this.setState({
				activeSearch: '',
				matchingFilters: [],
			})

			return false
		}

		const { filterValues, filter } = this.props

		const matchingFilters = this.props.searchForString(
			searchtext,
			filterValues,
			true,
			filter.get('visKode')
		)

		if (matchingFilters) {
			this.setState({
				activeSearch: searchtext.toLowerCase(),
				matchingFilters,
			})
		}
	}

	hasActiveTabState = (id) => id === this.state.activeTabStateId

	render() {
		const {
			filter,
			filterValues,
			filterStatus,
			selection,
			toggleFilterSelection,
			toggleFilterSelectionAll,
			toggleFilterSelectionAllSubLevel,
		} = this.props
		const { activeSearch, matchingFilters } = this.state

		// TODO: Flytt, fjern og rydd - denne skal styres av metadata og ikke hardkodes
		const shouldExpandLevel = filter.get('antNivaaAapen')

		if (!filterValues) return false

		const rootNode = filterValues.get(0)
		const filterValuesToRender =
			rootNode.get('skjult') === 1 ? rootNode.get('barn').toArray() : [rootNode.get('indeks')]

		return (
			<div className="nssReactApp">
				{filter.get('visSoekefelt') && (
					<FilterSearch
						searchHandler={this.searchHandler}
						placeholder={`Søk etter ${filter.get('navn').toLowerCase()}`}
					/>
				)}
				<div className="filter-values tree-filter text-overflow">
					<ul className="no-bullet">
						{filter.get('visVelgAlle') && matchingFilters.length < MAX_SEARCH_RESULTS && (
							<TreeFilterSelectAll
								filterId={filter.get('id')}
								filterValues={filterValues}
								filterValuesToRender={filterValuesToRender}
								matchingFilters={matchingFilters}
								activeSearch={activeSearch}
								selection={selection}
								selectAllHandler={toggleFilterSelectionAll}
								dashedLine={!filter.get('visVelgAlleUnder')}
							/>
						)}
						{filter.get('visVelgAlleUnder') && matchingFilters.length < MAX_SEARCH_RESULTS && (
							<TreeFilterSelectAllSubLevel
								filterId={filter.get('id')}
								filterValues={filterValues}
								filterValuesToRender={filterValuesToRender}
								matchingFilters={matchingFilters}
								activeSearch={activeSearch}
								selection={selection}
								selectAllSubLevelHandler={toggleFilterSelectionAllSubLevel}
								dashedLine
							/>
						)}
						{matchingFilters.length <= MAX_SEARCH_RESULTS &&
							filterValuesToRender.map((value, idx) => (
								<MultipleChoiceTreeFilterValue
									key={idx}
									index={value}
									filter={filter}
									showFilterCode={filter.getIn([
										'visKode',
										Math.max(filterValues.getIn([value, 'nivaa']) - 1, 0),
									])}
									setActiveTabState={this.setActiveTabState}
									hasActiveTabState={this.hasActiveTabState}
									filterValues={filterValues}
									filterStatus={filterStatus}
									matchingFilters={matchingFilters}
									activeSearch={activeSearch}
									selectAllHandler={toggleFilterSelectionAll}
									selectAllSublevelHandler={toggleFilterSelectionAllSubLevel}
									showChooseAll={filter.get('visVelgAlle')}
									showChooseAllSubLevel={filter.get('visVelgAlleUnder')}
									shouldExpand={filterValuesToRender.length <= 2}
									selection={selection}
									toggleFilterSelection={toggleFilterSelection}
									overrideExpandLevel={shouldExpandLevel}
								/>
							))}
						{activeSearch && !matchingFilters.length && (
							<div>
								<i>Søket ditt ga ingen treff</i>
							</div>
						)}
						{matchingFilters.length > MAX_SEARCH_RESULTS && (
							<div>
								<i>
									Søket ditt returnerte over {MAX_SEARCH_RESULTS} treff, vennligst spesifiser
									ytterligere
								</i>
							</div>
						)}
					</ul>
				</div>
			</div>
		)
	}
}
