import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Loading, Text, Card, H2} from '@utdanningsdirektoratet/lisa'
import './filterResult.css'
import { setShowFilterResult } from '../FilterOptions/ducks/filter/pageData'
import { useUrl } from '../../../hooks/useUrl'
import GetIcon from '../../../../components/getIcon/GetIcon'
import Utviklerdokumentasjon from '../../../../components/utviklerdokumentasjon/Utviklerdokumentasjon'
import axios from 'axios'

function FilterResult() {  
  const dispatch = useDispatch()
  let urlData = useUrl("/data")
  const selectedFormat = useSelector(state => state.filter.pageData.selectedFormat) 
  const selectedFilter = useSelector(state => state.filter.pageData.selectedFilter)
  const selectedTable = useSelector(state => state.filter.pageData.selectedTable)
  const showFilterResult = useSelector(state => state.filter.pageData.showFilterResult)
  const wrongInput = useSelector(state => state.filter.pageData.wrongPaginationInput)
  const [dataResponse, setDataResponse] = useState(null)
  const [dataResponseStatus, setDataResponseStatus] = useState(null)
  const [dataResponseError, setDataResponseError] = useState(null);
  const [loadingData, setLoadingData] = useState(false)
  
  const getFilterResult = async () => {
    setLoadingData(true)
    dispatch(setShowFilterResult(false))

    try {
      const response = await axios.get(urlData)

      setDataResponseStatus(JSON.stringify(response.status))
      setDataResponseError(null)

      if (selectedFormat === 0) {
        setDataResponse(JSON.stringify(response.data, null, " "))
      }
      else {
        setDataResponse(response.data)
      }

    } catch (error) {
      setDataResponseError(error)
      setDataResponseStatus(error.request.status)
    }
    dispatch(setShowFilterResult(true))
    setLoadingData(false)
  };

  useEffect(() => {
    dispatch(setShowFilterResult(false))
    setDataResponse(null)
    setDataResponseStatus(null)
  }, [selectedTable]);
  
  return (
    <Card backgroundColor="hvit" underlineColor="stålblå400">
      <Button disabled={!selectedFilter || wrongInput} onClick={getFilterResult}>Test datautvalg</Button>
      { loadingData && <Loading className="mt-20" pending={loadingData} size="large" /> }
      { showFilterResult && <>
        <Text className="mt-20" textStyle="bodyBold"><GetIcon label={<H2 textStyle="subtitle-large" className='mb-0 boldOverride'>Datautvalg for valgte filterverdier</H2>} /></Text>
        <Utviklerdokumentasjon 
          requesturl={urlData}
          responsebody={dataResponse}
          statuscode={dataResponseStatus}
          responseerror={dataResponseError}
          noHighlight
        />
      </> }
    </Card>
  )
}

export default FilterResult