import filterStringBuilder from './filterStringBuilder'

export default function urlBuilderFilterStatus(
	activeSelection,
	endpoint,
	otherParams = '',
	type = '',
	optionalQueries = ''
) {
	if (activeSelection.isEmpty()) return endpoint

	const tempFilter = filterStringBuilder(activeSelection)
	return `${endpoint.split('?')[0]}${type}?${otherParams}&filter=${tempFilter}${optionalQueries}`
}
