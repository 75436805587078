import React from 'react'
import searchForString from '../../util/searchForString'
import TooltipConnector from '../../tooltip/TooltipConnector'
import MultipleChoiceFilter from '../multiple-choice-filter/MultipleChoiceFilter'
import SingleChoiceFilter from '../single-choice-filter/SingleChoiceFilter'
import MultipleChoiceTreeFilter from '../multiple-choice-tree-filter/MultipleChoiceTreeFilter'
import SingleChoiceTreeFilter from '../single-choice-tree-filter/SingleChoiceTreeFilter'
import FilterHeaderConnector from '../filter-header/FilterHeaderConnector'
import ToggleFilter from '../toggle-filter/ToggleFilter'

const FILTER_TYPES = {
	flat: {
		multipleChoice: MultipleChoiceFilter,
		singleChoice: SingleChoiceFilter
	},
	hierarkisk: {
		multipleChoice: MultipleChoiceTreeFilter,
		singleChoice: SingleChoiceTreeFilter
	},
	flagg: ToggleFilter
}

const FilterType = ({
	filter,
	filterValues,
	selection,
	setFilterSelection,
	toggleFilterSelection,
	toggleFilterSelectionAll,
	toggleFilterSelectionAllSubLevel,
	filterStatus,
	filterTextId
}) => {
	if (!filterValues) return false

	const selectedFilterType = FILTER_TYPES[filter.get('filterType')]
	const ChosenFilterComponent =
		filter.get('filterType') === 'flagg'
			? selectedFilterType
			: selectedFilterType[filter.get('flervalg') ? 'multipleChoice' : 'singleChoice']

	if (!ChosenFilterComponent)
		return (
			<div>
				<h2>{filter.name}</h2>Filter of type "{filter.type}" not implemented
			</div>
		)

	const filterHelpText = (
		<TooltipConnector
			textId={filterTextId}
			textKey={filterTextId} // Already unique
			name={filter.get('navn')}
			type="filter"
			icon="help"
		/>
	)

	const componentToRender = (
		<ChosenFilterComponent
			filter={filter}
			filterValues={filterValues}
			setFilterSelection={setFilterSelection}
			toggleFilterSelection={toggleFilterSelection}
			toggleFilterSelectionAll={toggleFilterSelectionAll}
			toggleFilterSelectionAllSubLevel={toggleFilterSelectionAllSubLevel}
			selection={selection}
			filterStatus={filterStatus}
			searchForString={searchForString}
		>
			{ChosenFilterComponent === ToggleFilter && filterHelpText}
		</ChosenFilterComponent>
	)

	if (ChosenFilterComponent !== ToggleFilter) {
		return (
			<FilterHeaderConnector
				filter={filter}
				filterTextEndpoint={filter.get('beskrivelsesEndepunkt')}
			>
				{filterHelpText}
				{componentToRender}
			</FilterHeaderConnector>
		)
	}
	return componentToRender
}

export default FilterType
