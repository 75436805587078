import React from 'react'
import { Icon, MultiSelect, SearchTable } from '@utdanningsdirektoratet/lisa'
import { setChosenDatasettFilters} from "../../app/views/ApiKonsoll/FilterOptions/ducks/filter/pageData"
import { useDispatch, useSelector } from 'react-redux';


function LisaSerachTable({ className, tableContent, caption }) {
    const dispatch = useDispatch();
    const selectedYears = useSelector(state => state.filter.pageData.chosenDatasettFilters)
      
    const kolonner = [
        {
            headerName: "Last ned",
            displayFunction: (item) => <a className="text-colore-blue" href={item?.Url}>{<Icon type="download"/>}</a>,
        },
        {
            headerName: "Tabell",
            displayFunction: (item) => item?.Rapport,
            sortable: true
        },
        {
            headerName: "Skoleår",
            displayFunction: (item) => item?.Aar,
            initiallySorted: "dscending",
            sortable: true
        },
        {
            headerName: "Oppdatert",
            displayFunction: (item) => item?.OppdatertDato,
            sortable: true
        },
        {
            headerName: "Publisert",
            displayFunction: (item) => item?.PubliseresDato,
            sortable: true
        },
        {
            headerName: "Størrelse",
            displayFunction: (item) => item?.FilStorrelse,
            sortable: true
        },
    ];

    const filterContent = Array.from(new Set(tableContent.map(item => item.Aar)))
        .sort((a, b) => {
            const year1 = +a.split("-")[0];
            const year2 = +b.split("-")[0];
            if (year1 === year2) {
                const subYear1 = +a.split("-")[1] || 0;
                const subYear2 = +b.split("-")[1] || 0;
                return subYear2 - subYear1;
            }
            return year2 - year1;
        })
        .map(year => ({
            label: year.toString(),
            id: year.toString(),
            isChecked: selectedYears.includes(year.toString()),
            onChange: (isChecked) => {
                if (isChecked) {
                    dispatch(setChosenDatasettFilters([...selectedYears, year.toString()]));
                } else {
                    dispatch(setChosenDatasettFilters(selectedYears.filter(y => y !== year.toString())));
                }
            }
        }
    ));

    return (
        <div className={`${className}`}>       
            <SearchTable
            kind="table"
            items={tableContent}
            columns={kolonner}
            caption={caption}
            pendingProps={{ numberOfRows: tableContent?.length }}
            //Søk
            searchprops={{
                id: "tabellsøk",
                searchBarLabel: "Søk",
                hideLabel: false,
                searchBarPlaceholder: "Søk etter tabell eller år",
                searchValueFunction: (item) => `${item?.Rapport} ${item?.Aar}`
            }}
            // Filter
            actions={[
                <MultiSelect
                    label="Velg år"
                    key="dropdown1"
                    header="År"
                    items={filterContent}
                />
            ]}
            filterFunction={(item) => {
                if (selectedYears.length === 0) {
                    return true;
                }
                return selectedYears.includes(item?.Aar.toString());
            }}
            />
        </div>
    );
}

export default LisaSerachTable