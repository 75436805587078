import { Seq } from 'immutable'

export function fromJSListToSet(js) {
	if (typeof js !== 'object' || js === null) return js

	if (Array.isArray(js))
		return Seq(js)
			.map(fromJSListToSet)
			.toSet()

	return Seq(js)
		.map(fromJSListToSet)
		.toMap()
}

export function fromJSOrdered(js) {
	if (typeof js !== 'object' || js === null) return js

	if (Array.isArray(js))
		return Seq(js)
			.map(fromJSOrdered)
			.toList()

	return Seq(js)
		.map(fromJSOrdered)
		.toOrderedMap()
}
