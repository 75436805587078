
const lowerCaseFilterId = (filterValues, filterId) => filterId.toLowerCase()
const filterToString = (filterValues, filterId) =>
	`${filterId}(${filterValues.isEmpty() ? '*' : filterValues.sort().join('_')})`

function filterStringBuilder(activeSelection) {
	if (activeSelection.isEmpty()) return ''

	return activeSelection
		.sortBy(lowerCaseFilterId)
		.map(filterToString)
		.join('_')
}

export default filterStringBuilder
