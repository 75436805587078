import React, { useEffect, useState } from 'react'
import { Loading, Select, Grid, GridItem, Accordion, Text, H2, Card, H3 } from '@utdanningsdirektoratet/lisa'
import FilterPanelConnector from '../FilterOptions/filter/filter-panel/FilterPanelConnector'
import { useDispatch, useSelector, useStore } from 'react-redux'
import LisaTooltip from "../../../../components/listaTooltip/LisaTooltip";
import "./FilterURL.css"
import { setLoadingFilterVerdier, setFilterSpec, setLoadingFilterSpec, setLoadingSideData, setNumberOfPages, setSideData, setFilterVerdier, setSelectedPage } from "../FilterOptions/ducks/filter/pageData";
import { useUrl } from '../../../hooks/useUrl';
import { fetchFilterSpec } from '../FilterOptions/ducks/filter/filterSpec';
import { receiveFilterValues } from '../FilterOptions/ducks/filter/filterValues';
import { setSelectedFormat } from '../FilterOptions/ducks/filter/pageData'
import SyntaxHighlight from '../../../../components/syntaxHighlighter/SyntaxHighlight';
import GetIcon from '../../../../components/getIcon/GetIcon';
import { setSelection } from '../FilterOptions/ducks/filter/activeSelection';
import { setSelectedFilter } from '../FilterOptions/ducks/filter/pageData';
import { fromJSListToSet } from '../FilterOptions/util/immutableHelpers';
import Utviklerdokumentasjon from '../../../../components/utviklerdokumentasjon/Utviklerdokumentasjon';
import Paginering from '../../../../components/paginering/Paginering';
import { Alert, AlertTitle } from '@mui/material';
import UtviklerdokumentasjonFilterStatus from '../../../../components/utviklerdokumentasjon/UtviklerdokumentasjonFilterStatus';
import axios from 'axios';

function Filter() {
  const dispatch = useDispatch()
  const store = useStore()

  const urlData = useUrl("/data")
  const urlFilterSpec = useUrl("/filterSpec")
  const urlFilterVerdier = useUrl("/filterVerdier")
  const urlSideData = useUrl("/sideData")
  const filterSpec = useSelector(state => state.filter.pageData.filterSpecResponse)
  const filterVerdier = useSelector(state => state.filter.pageData.filterVerdierResponse)
  const sideData = useSelector(state => state.filter.pageData.sideDataResponse)
  const selectedFilter = useSelector(state => state.filter.pageData.selectedFilter)
  const selectedFormat = useSelector(state => state.filter.pageData.selectedFormat)
  const selectedPage = useSelector(state => state.filter.pageData.selectedPage) 
  const formatResponse = useSelector(state => state.filter.pageData.formatResponse).Format
  const selectedTable = useSelector(state => state.filter.pageData.selectedTable)
  const loadingFilterSpec = useSelector(state => state.filter.pageData.loadingFilterSpecResponse)
  const loadingFilterVerdier = useSelector(state => state.filter.pageData.loadingFilterVerdierResonse)
  const loadingSideData = useSelector(state => state.filter.pageData.loadingSideDataResponse)
  const defaultUttrykk = useSelector(state => state.filter.pageData.defaultUttrykk)
  const filterStatusError = useSelector (state => state.filter.filterStatus.get('error'))
  const [availableFormats, setAvailableFormats] = useState([]);
  const [filterSpecResponseStatus, setFilterSpecResponseStatus] = useState(null)
  const [filterSpecResponseError, setFilterSpecResponseError] = useState(null);
  const [filterVerdierResponseStatus, setFilterVerdierResponseStatus] = useState(null)
  const [filterVerdierResponseError, setFilterVerdierResponseError] = useState(null);
  const [sideDataResponseStatus, setSideDataResponseStatus] = useState(null)
  const [sideDataResponseError, setSideDataResponseError] = useState(null);
  
  useEffect(() => {
    //Bare dispatch om objektet ikke er tomt.
    if(Object.keys(defaultUttrykk).length){
      dispatch(setSelection(fromJSListToSet(defaultUttrykk))) 
    }
    
    //Bare dispatch hvis activeSelection har blitt satt.
    if((store.getState().filter.activeSelection.size > 0) )  {
      dispatch(setSelectedFilter(store.getState().filter.activeSelection))
    }
  }, [loadingFilterVerdier, defaultUttrykk, dispatch, store])

  const getFilterSpecResponse = async () => { 
    dispatch(setLoadingFilterSpec(true))
    try {
      const response = await axios.get(urlFilterSpec)
      setFilterSpecResponseError(null)
      setFilterSpecResponseStatus(response.status)
      dispatch(setFilterSpec(response.data))
      dispatch(fetchFilterSpec())

    } catch (error) {
      setFilterSpecResponseError(error)
      setFilterSpecResponseStatus(error.request.status)

      //OBS Hacky løsning fordi Lisa ikke lar deg overstyre om komponent er åpen/lukket.
      document.getElementById("accordion-filter-spec").getElementsByTagName("details")[0].open = true;
    }
    dispatch(setLoadingFilterSpec(false))
  }

  const getFilterValuesResponse = async () => {   
    dispatch(setLoadingFilterVerdier(true))
    try {
      const response = await axios.get(urlFilterVerdier)
      setFilterVerdierResponseStatus(response.status)
      setFilterVerdierResponseError(null)
      dispatch(setFilterVerdier(response.data))
      dispatch(receiveFilterValues(response.data))
    
    } catch (error) {
      setFilterVerdierResponseError(error)
      setFilterVerdierResponseStatus(error.request.status)

      //OBS Hacky løsning fordi Lisa ikke lar deg overstyre om komponent er åpen/lukket.
      document.getElementById("accordion-filter-verdier").getElementsByTagName("details")[0].open = true;
    }
    dispatch(setLoadingFilterVerdier(false))
  }

  const getSideDataResponse = async () => {
    dispatch(setLoadingSideData(true))
    try {
      const response = await axios.get(urlSideData)
      setSideDataResponseStatus(response.status)
      setSideDataResponseError(null)
      dispatch(setSideData(response.data))
      dispatch(setSideData(response.data))

      if (selectedFormat === 0) {
        if (selectedPage > response.data[0].JSONSider) {
          dispatch(setSelectedPage(1))
      }} else {
        if (selectedPage > response.data[0].CSVSider) {
          dispatch(setSelectedPage(1))
      }}

    } catch (error) {
      setSideDataResponseError(error)
      setSideDataResponseStatus(error.request.status)

      //OBS Hacky løsning fordi Lisa ikke lar deg overstyre om komponent er åpen/lukket.
      document.getElementById("accordion-side-data").getElementsByTagName("details")[0].open = true;
    }
    dispatch(setLoadingSideData(false))
  }

  const getNumberofPages = () => {
    if (sideData !== null) {
      if (selectedFormat === 4) {
        dispatch(setNumberOfPages(sideData[0].CSVSider))
      }
      else {
        dispatch(setNumberOfPages(sideData[0].JSONSider))
      }
    }
  }

  useEffect(() => {
    setAvailableFormats(formatResponse.map(item => ({ value: item.FormatKode, label: item.FormatNavn })));
  }, [formatResponse]);

  useEffect(() => {
    getFilterSpecResponse()
    getFilterValuesResponse()
  }, [selectedTable]);

  useEffect(() => {
    if (selectedFilter && !(filterSpecResponseError || filterVerdierResponseError)) {
    getSideDataResponse()
    }
  }, [selectedFilter]);

  useEffect(() => {
    getNumberofPages()
  }, [sideData, selectedFormat]);

  return (
    <Card backgroundColor="hvit" underlineColor="stålblå400">
      <div className='mb-20'>
        <Text className="mb-12" textStyle="bodyBold"><GetIcon label={<LisaTooltip noMargin label={<H2 textStyle="subtitle-large" className='mb-0 boldOverride'>URL-spørring</H2>} content="Hent ut dataene fra tabellen ved å trykke på kopier-ikonet og lim URL-en inn i nettleseren din. Filterverdier, valg av format og side er gjenspeilet i URL-en."></LisaTooltip>} /></Text>
        <div><SyntaxHighlight label="URL-spørring" language="language-javascript" code={urlData}/></div>
      </div>
      <Grid gap="none">
        <GridItem className="mr-20 mb-20 format-min-width" columns={{}}>
          <LisaTooltip label={<H2 textStyle="subtitle-large" className='mb-0 boldOverride'>Velg format</H2>} content="Velg filformat på datautvalg med nedtrekksmenyen."></LisaTooltip>
            {availableFormats.length &&
              <Select
                label="Velg format"
                hideLabel={true}
                dropdownStyle="outlined"
                width="auto"
                value={selectedFormat}
                onChange={(value) => dispatch(setSelectedFormat(value))}
                items={availableFormats}
              />
            }
        </GridItem>
        <GridItem className="mb-20 pagination-min-width" columns={{xsmall:12, small:11}}>
          <LisaTooltip label={<H2 textStyle="subtitle-large" className='mb-0 boldOverride'>Velg side</H2>} content="Ved store datamengder splittes datautvalget til tabellen opp i sider. Benytt pil-knappene eller skriv inn ønsket sidenummer for å endre side."></LisaTooltip>          
          {sideDataResponseError ? 
            <Alert className="mb-8" severity="warning">
              <AlertTitle>Klarte ikke laste tilgjengelige sider</AlertTitle>
              API-kallet for å hente antall sider feilet. 
            </Alert> : 
            <>
              {loadingSideData ? <Loading className="mt-20 mb-20" pending={loadingSideData} size="large" /> :
              <Paginering></Paginering>}
            </>
          }
        </GridItem>
      </Grid>
      <Grid>
          <GridItem className="mb-8" columns={{xsmall:12, small: 12, medium: 4 }}>
            <div className="filter-header-size">
                <LisaTooltip label={<H2 textStyle="subtitle-large" className='mb-0 boldOverride'>Velg filter</H2>} content="Trykk på et filter for å åpne det, og bruk avmarkeringsboksene til å justere filtreringen."></LisaTooltip>
                { filterSpecResponseError !== null ? 
                  <Alert className="mb-8" severity="warning">
                    <AlertTitle>Klarte ikke laste filter</AlertTitle>
                    API-kall feilet og lastet ikke inn standard-alternativ for filterverdier. Det er ikke mulig å teste filtervalg uten filterverdier.
                  </Alert> : 
                  <>
                    {(sideDataResponseError !== null || filterVerdierResponseError !== null) && 
                    <Alert className="mb-8" severity="warning">
                      <AlertTitle>Klarte ikke laste filter</AlertTitle>
                      API-kall feilet. Du kan fremdeles teste filtervalg med tabellens standard-alternativ for filterverdier.
                    </Alert>}
                  </>
                }
                {(filterStatusError) && 
                  <Alert className="mb-8" severity="error">
                    <AlertTitle>Klarte ikke laste filter</AlertTitle>
                    API-kall til 'Filter Status' feilet - {filterStatusError.code !== "ERR_NETWORK" && <>{filterStatusError.response.status} - {filterStatusError.response.statusText}</>}
                  </Alert> 
                }
                { !(filterSpecResponseError !== null || filterVerdierResponseError !== null || sideDataResponseError !== null || filterStatusError) &&  
                  <>
                    { (loadingFilterSpec || loadingFilterVerdier ) ? 
                      <Loading className="mt-20 mb-20" pending={loadingFilterSpec || loadingFilterVerdier} size="large" /> : 
                      <FilterPanelConnector/>
                    }
                  </>
                }
          </div>
          </GridItem>
          <GridItem columns={{ xsmall:12, small: 12, medium: 8}} className = "lesKoden">
              <LisaTooltip label={<H2 textStyle="subtitle-large" className='mb-0 boldOverride text-overflow'>Utviklerdokumentasjon</H2>} content="Utviklerdokumentasjonen gir mer informasjon om hvordan API-et er satt opp, og hvordan man kan hente ut ønsket datautvalg."></LisaTooltip>
              <div id="accordion-filter-spec">
                <Accordion className="border-bottom p-4" title={<GetIcon label={<H3 textStyle="bodyBold" className='m-0'>Filtre i valgt tabell</H3>}/>}>
                  { loadingFilterSpec ? <Loading className="mt-20 mb-20" pending={loadingFilterSpec} size="large" />: 
                  <Utviklerdokumentasjon
                    requesturl={urlFilterSpec}
                    responsebody={JSON.stringify(filterSpec, null, " ")}
                    responseerror={filterSpecResponseError}
                    statuscode={filterSpecResponseStatus}
                    noHighlight
                  />
                  }
                </Accordion>
              </div>
              <div id="accordion-filter-verdier">
                <Accordion tagName="details" className="border-bottom p-4" title={<GetIcon label={<H3 textStyle="bodyBold" className='m-0'>Filterverdier i valgt tabell</H3>}/>}>
                  { loadingFilterVerdier ? <Loading className="mt-20 mb-20" pending={loadingFilterVerdier} size="large" />: 
                  <Utviklerdokumentasjon
                    requesturl={urlFilterVerdier}
                    responsebody={JSON.stringify(filterVerdier, null, " ")}
                    responseerror={filterVerdierResponseError}
                    statuscode={filterVerdierResponseStatus}
                    noHighlight
                  />
                  }
                </Accordion>
              </div>
              <Accordion className="border-bottom p-4" title={<GetIcon label={<H3 textStyle="bodyBold" className='m-0'>Gyldig filtervalg for ett bestemt filter i valgt tabell, for valgte filterverdier</H3>}/>}>
                { loadingFilterSpec ? <Loading className="mt-20 mb-20" pending={loadingFilterSpec} size="large" />: 
                <>
                    <UtviklerdokumentasjonFilterStatus/> 
                </>
                }
              </Accordion>
              <div id="accordion-side-data">
                <Accordion className="border-bottom p-4" title={<GetIcon label={<H3 textStyle="bodyBold" className='m-0'>Antall sider, rader og bytes i valgt tabell, for valgte filterverdier</H3>}/>}>
                  { loadingSideData ? <Loading className="mt-20 mb-20" pending={loadingSideData} size="large" />: 
                  <>
                    {(filterVerdierResponseError || filterSpecResponseError) ?  
                      <Alert className="mb-8" severity="warning">
                        <AlertTitle>Klarte ikke laste antall rader, sider og bytes</AlertTitle>
                      </Alert> : 
                      <Utviklerdokumentasjon
                      requesturl={urlSideData}
                      responsebody={JSON.stringify(sideData, null, " ")}
                      responseerror={sideDataResponseError}
                      statuscode={sideDataResponseStatus}
                    /> }
                  </>
                  }
                </Accordion>
              </div>
          </GridItem>
        </Grid>
    </Card>
  )
}
export default Filter