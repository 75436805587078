export const types = {
	TOGGLE_SHOW_HIDDEN_DATA: 'TOGGLE_SHOW_HIDDEN_DATA'
}

export default (state = true, action) => {
	switch (action.type) {
		case types.TOGGLE_SHOW_HIDDEN_DATA:
			return action.show
		default:
			return state
	}
}

export const toggleShowHiddenData = show => ({
	type: types.TOGGLE_SHOW_HIDDEN_DATA,
	show
})
